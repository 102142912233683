import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { mask, unMask } from 'remask';
import moment from "moment";
import 'moment/locale/pt-br';

import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'

import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import AddIcon from '@mui/icons-material/Add';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import BlockIcon from '@mui/icons-material/Block';
import BusinessIcon from '@mui/icons-material/Business';
import CheckIcon from '@mui/icons-material/Check';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FactoryIcon from '@mui/icons-material/Factory';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import StoreIcon from '@mui/icons-material/Store';
import TaskIcon from '@mui/icons-material/Task';
import WarningIcon from '@mui/icons-material/Warning';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';



/// DATATABLE ICONS ////////////////////////////////////////////////////////////

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


export const MyCompany = () => {

    const navigate = useNavigate();
    const {register, handleSubmit, formState: { errors }, reset} = useForm({ mode: 'resolver' });

    const { state: user } = useContext(UserContext)

    const [state, setState] = useState('')
    const [list, setList] = useState([]);
    const [myCompanyData, setMyCompanyData] = useState([])
    const [verify, setVerify] = useState(false)
    const [vrfData, setVrfData] = useState(false)

    const [loading, setLoading] = useState(true)
    const [stateErrors, setStateErros] = useState(false);


    
/// MASK ///////////////////////////////////////////////////////////////////////

  const [cnpjNumber, setCnpjNumber] = useState('')
  const onChangeCnpj = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['99.999.999/9999-99']);
    setCnpjNumber(unMask(maskedValue));
  }


  const [cepNumber, setCepNumber] = useState('')
  const onChangeCep = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['99999-999']);
    setCepNumber(unMask(maskedValue));
  }
  

  const [phoneNumber, setPhoneNumber] = useState('')
  const onChangePhone = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['(99) 9999-9999']);
    setPhoneNumber(unMask(maskedValue));
  }


  const [cellNumber, setCellNumber] = useState('')
  const onChangeCell = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['(99) 9 9999-9999']);
    setCellNumber(unMask(maskedValue));
  }


////////////////////////////////////////////////////////////////////////////////



    


/// FUNCTIONS ////////////////////////////////////////////////////////////////


  const GetMyCompany = async () => {
    setLoading(true)
    // console.log("Loading", true)

    let res = await Api.getMyCompany()
    setList(res)
    // console.log("GetMyCompany > list", list)

    if (res.length === 0) {
      setVerify(false)
      
    } else {
      setVerify(true)
      setVrfData(true)
    }
    
    setMyCompanyData(res)
    console.log("GetMyCompany > myCompanyData", res)

    setCnpjNumber((res.length === 0 || res[0].mnh_emprs_cnpj === null || res[0].mnh_emprs_cnpj === undefined) ? '' : res[0].mnh_emprs_cnpj)
    // console.log("GetMyCompany > myCompanyData > CNPJ", res[0].mnh_emprs_cnpj)
    setCepNumber((res.length === 0 || res[0].mnh_emprs_cep === null || res[0].mnh_emprs_cep === undefined) ? '' : res[0].mnh_emprs_cep)
    // console.log("GetMyCompany > myCompanyData > CEP", res[0].mnh_emprs_cep)
    setPhoneNumber((res.length === 0 || res[0].mnh_emprs_telefone === null || res[0].mnh_emprs_telefone === undefined) ? '' : res[0].mnh_emprs_telefone)
    // console.log("GetMyCompany > myCompanyData > TEL", res[0].mnh_emprs_telefone)
    setCellNumber((res.length === 0 || res[0].mnh_emprs_celular === null || res[0].mnh_emprs_celular === undefined) ? '' : res[0].mnh_emprs_celular)
    // console.log("GetMyCompany > myCompanyData > CEL", res[0].mnh_emprs_celular)

    setLoading(false)
    // console.log("Loading", false)
  }



  const onSubmit = async (data, option) => {
    // console.log("Option", option)

    data["mnh_emprs_cnpj"] = cnpjNumber;
    data["mnh_emprs_cep"] = cepNumber;
    data["mnh_emprs_telefone"] = phoneNumber;
    data["mnh_emprs_celular"] = cellNumber;
    // console.log(data)

    if (cnpjNumber === null || cnpjNumber === '' 
      || cepNumber === null || cepNumber === ''
      || phoneNumber === null || phoneNumber === ''
      || cellNumber === null || cellNumber === ''
    ) {
      setStateErros(true)
    } else {
      setStateErros(false)

      switch (option) {
        
        case 1:
          const userAdd = ({
            mnh_emprs_usuario_criacao: user.id,
            mnh_emprs_usuario_atualizacao: user.id
          })
          const add = ({...data, ...userAdd})
          let res1 = await Api.postMyCompany(add)
          // console.log("addCompany > res1 : ", res1)
          break;

        case 2:
          const userUpd = ({
            mnh_emprs_usuario_atualizacao: user.id
          })
          const upd = ({...data, ...userUpd})
          let res2 = await Api.postMyCompany(upd)
          // console.log("updCompany > res2 : ", res2)
          break;

        default:
          break;
      }

      // console.log(data)

      GetMyCompany()

    }
  };






/// USE EFECT //////////////////////////////////////////////////////////////////

    useEffect(()=>{
      GetMyCompany(); 
    }, [state]);


    
/// CONSOLE LOG ////////////////////////////////////////////////////////////////

  // console.log("CPJ: ", cnpjNumber)
  // console.log("CEP: ", cepNumber)
  // console.log("TEL: ", phoneNumber)
  // console.log("CEL: ", cellNumber)
  
  // console.log(localStorage.getItem('SessionID'))

  // console.log("myCompanyData: ", myCompanyData[0])
  // console.log("list", list)
  // console.log("myCompanyData: ", myCompanyData)
  console.log("verify", verify)
  console.log("vrfData", vrfData)
  // console.log("ObjectErrors", Object.keys(errors))
  // console.log("stateErrors", stateErrors)







  return (
    <G.Container>

      {/* <G.Header>
        <G.HeaderTitleArea>
          <AppRegistrationOutlinedIcon fontSize='large' style={{ marginRight: "7px" }} />
          <G.MainTitle>| Cadastro</G.MainTitle>
        </G.HeaderTitleArea>
      </G.Header> */}

      <G.Header>
        <G.HeaderTitleArea style={{ width: '240px' }}>
          <StoreIcon style={{ fontSize: '32px',margin: '0px 10px 0px 0px' }}/>
          <G.MainTitle>Minha Empresa</G.MainTitle>
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '320px' }}>
          
        </G.HeaderTitleArea>

        <G.HeaderTitleArea style={{ width: '120px', justifyContent: 'end' }}>
        {(myCompanyData.length === 0 && verify === false) &&
            <G.Button 
              style={{ backgroundColor: 'var(--primary-color)', justifyContent: 'space-around' }} 
              onClick={() => setVerify(true)}
            >
              <AddIcon fontSize='small'/>
              {'Adicionar'}
            </G.Button>
          }
          {(myCompanyData.length === 0 && verify === true) &&
            <G.Button 
              style={{ backgroundColor: 'var(--special-color)', justifyContent: 'space-around' }} 
              onClick={() => handleSubmit(onSubmit)(1)}
            >
              <AddIcon fontSize='small'/>
              {'Cadastrar'}
            </G.Button>
          }
          {(myCompanyData.length > 0 && verify === true) &&
            <G.Button 
              style={{ backgroundColor: '#2E8B57', justifyContent: 'space-around' }} 
              onClick={() => handleSubmit(onSubmit)(2)}
            >
              <CheckIcon fontSize='small'/>
              {'Atualizar'}
            </G.Button>
          }
        </G.HeaderTitleArea>
      </G.Header>

      <G.Body>

        {loading &&  
          <G.Logo src={"/loading.svg"} />
        }

        {(!loading && verify === false) &&
          <G.OptionsArea style={{ justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column' }}>
            <ErrorOutlineRoundedIcon style={{fontSize: '80px', color: '#aaa' }}/>
            <G.MainTitle style={{ fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
              Não existem dados cadastrados! Clique no botão Adicionar para cadsatrar os dados.
            </G.MainTitle>
          </G.OptionsArea>
        }

        {(!loading && verify === true) && 
          <>
            <G.Box style={{ width: '984px' }}>

              <G.BoxBody style={{ width: '960px', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '12px', flexWrap: 'wrap', marginTop: '5px' }}>
                
                <G.FormInput style={{ width: '80px'}} type="hidden" defaultValue={!vrfData ? '' : myCompanyData[0].mnh_emprs_id} {...register("mnh_emprs_id", { setValueAs: v => v === '' ? null : v })} />

                <G.FormInputArea style={{ width: '472px' }}>
                  <G.FormInputLabel>
                    Nome
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].mnh_emprs_nome} {...register("mnh_emprs_nome", { required: true })} />
                </G.FormInputArea>
                <G.FormInputArea style={{ width: '464px' }}>
                  <G.FormInputLabel>
                    Fantasia
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].mnh_emprs_fantasia} {...register("mnh_emprs_fantasia", { required: true })} />
                </G.FormInputArea>

                <G.FormInputArea style={{ width: '304px' }}>
                  <G.FormInputLabel>
                    CNPJ
                  </G.FormInputLabel>
                  <G.FormInput 
                    type='text'
                    name='cnpjNumber'
                    placeholder=''
                    value={mask(cnpjNumber, ['99.999.999/9999-99'])}
                    onChange={onChangeCnpj}
                  />
                </G.FormInputArea>

                <G.FormInputArea style={{ width: '304px' }}>
                  <G.FormInputLabel>
                    I.E.
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].mnh_emprs_ie} {...register("mnh_emprs_ie", { required: true })} />
                </G.FormInputArea>

                <G.FormInputArea style={{ width: '316px' }}>
                  <G.FormInputLabel>
                    I.M.
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].mnh_emprs_im} {...register("mnh_emprs_im", { required: true })} />
                </G.FormInputArea>

                <G.FormInputArea style={{ width: '212px' }}>
                  <G.FormInputLabel>
                    Telefone
                  </G.FormInputLabel>
                  <G.FormInput 
                    type='text'
                    name='phoneNumber'
                    placeholder=''
                    value={mask(phoneNumber, ['(99) 9999-9999'])}
                    onChange={onChangePhone}
                  />
                </G.FormInputArea>
                <G.FormInputArea style={{ width: '132px' }}>
                  <G.FormInputLabel>
                    Ramal
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].mnh_emprs_ramal} {...register("mnh_emprs_ramal", { required: true })} />
                </G.FormInputArea>
                <G.FormInputArea style={{ width: '212px' }}>
                  <G.FormInputLabel>
                    Celular
                  </G.FormInputLabel>
                  <G.FormInput 
                    type='text'
                    name='cellNumber'
                    placeholder=''
                    value={mask(cellNumber, ['(99) 9 9999-9999'])}
                    onChange={onChangeCell}
                  />
                </G.FormInputArea>
                <G.FormInputArea style={{ width: '356px' }}>
                  <G.FormInputLabel>
                    E-mail
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].mnh_emprs_email} {...register("mnh_emprs_email", { required: true })} />
                </G.FormInputArea>

                <G.FormInputArea style={{ width: '504px' }}>
                  <G.FormInputLabel>
                    Endereço
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].mnh_emprs_endereco} {...register("mnh_emprs_endereco", { required: true })} />
                </G.FormInputArea>
                <G.FormInputArea style={{ width: '432px' }}>
                  <G.FormInputLabel>
                    Complemento
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].mnh_emprs_complemento} {...register("mnh_emprs_complemento", { required: true })} />
                </G.FormInputArea>
                <G.FormInputArea style={{ width: '400px' }}>
                  <G.FormInputLabel>
                    Bairro
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].mnh_emprs_bairro} {...register("mnh_emprs_bairro", { required: true })} />
                </G.FormInputArea>

                <G.FormInputArea style={{ width: '400px' }}>
                  <G.FormInputLabel>
                    Cidade
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].mnh_emprs_cidade} {...register("mnh_emprs_cidade", { required: true })} />
                </G.FormInputArea>
                <G.FormInputArea style={{ width: '104px' }}>
                  <G.FormInputLabel>
                    UF
                  </G.FormInputLabel>
                  <G.FormInput maxLength={2} defaultValue={!vrfData ? '' : myCompanyData[0].mnh_emprs_uf} {...register("mnh_emprs_uf", { required: true }, { setValueAs: v => v.toUpperCase() })} />
                </G.FormInputArea>
                <G.FormInputArea style={{ width: '192px' }}>
                  <G.FormInputLabel>
                    CEP
                  </G.FormInputLabel>
                  <G.FormInput 
                    type='text'
                    name='cepNumber'
                    placeholder=''
                    value={mask(cepNumber, ['99999-999'])}
                    onChange={onChangeCep}
                  />
                </G.FormInputArea>


                <G.BreakLine style={{ height: '12px' }}/>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                  {(Object.keys(errors).length > 0 || stateErrors) &&  
                    <div role="alert" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '72%', padding: '7px', margin: '16px 0px 0px 0px', borderRadius: '4px', fontSize: '12px', backgroundColor: '#FCAC9B', color: '#000' }}>
                      <div style={{ width: '08%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><WarningRoundedIcon style={{ color: '#333', marginRight: '5px' }} /></div>
                      <div style={{ width: '84%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{'TODOS OS CAMPOS DEVEM ESTAR PREENCHIDOS!'}</div>
                      <div style={{ width: '08%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><WarningRoundedIcon style={{ color: '#333', marginRight: '5px' }} /></div>
                    </div>
                  }
                </div>

                <G.BreakLine style={{ height: '12px' }}/>

                <G.FieldArea style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: '10px', marginBottom: '10px' }}>
                  <G.FormInputLabel style={{ width: '920px', border: 'none', backgroundColor: '#f0f0f0', borderRadius: '5px 5px 0px 0px', borderBottom: '1px solid #E0E0E0' }}>
                    Observações
                  </G.FormInputLabel>
                  <G.TextArea 
                    {...register("mnh_emprs_observacao", { setValueAs: v => v === '' ? null : v })} 
                    style={{ width: '900px', height: '96px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '0px 0px 5px 5px', fontFamily: 'inherit', color: '#222', fontSize: '14px' }}
                  >
                    {!vrfData ? '' : myCompanyData[0].mnh_emprs_observacao}
                  </G.TextArea>
                </G.FieldArea>

              </G.BoxBody>

            </G.Box>
          </>
        }
      </G.Body>


      <G.Footer style={{ color: '#333', fontSize: '14px' }}>
        {/* <p> Copyright <CopyrightIcon style={{ fontSize: '14px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
      </G.Footer>

    </G.Container>

  );
}
