/// API ////////////////////////////////////////////////////////////////////////
const GPP_API = process.env.REACT_APP_BACKEND;







///////////////////////////////////////////////////////////////////////////////

export default {


	/// - LOGIN - //////////////////////////
	/// ------------------------------------

	login: async (User, Password) => {
		const req = await fetch(`${GPP_API}/auth`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				'login' : User,
				'password': Password
			})
		});
		const response = await req.json();
		return response;
	},


	checkToken: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/session?token=${token}`)
		const response = await req.json();
		return response;
	},


	renew: async (login, pw) => {
		const req = await fetch(`${GPP_API}/renew`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				{
					"login": login,
					"pw": pw
				}
			)
		});
		const response = await req.json();        
		return response;
	},


	getMail: async (login) => {
		const req = await fetch(`${GPP_API}/renew?login=${login}`)
		const response = await req.json();        
		return response;
	},


	sendMail: async (mailDest, mailBody) => {
		const req = await fetch(`${GPP_API}/sendMail`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				{
					"dest": mailDest,
					"subject": 'Token para criação de nova senha',
					"body": mailBody,
				}
			)
		});
		const response = await req.json();        
		return response;
	},

	/// ------------------------------------


	/// - ARQUIVOS - ///////////////////////
	/// ------------------------------------

	uploadFile: async (file) => {
		const token = await localStorage.getItem('SessionID');
		let formData = new FormData()
		formData.append('file', file)
		const req = await fetch(`${GPP_API}/upload?token=${token}`, {
			method: 'POST',
			headers: {
				Accept: 'multipart/form-data',
			},
			body: formData
		})
		return req;
	},


	deleteFile: async (file) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/delete?file=${file}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();        
		return response;
	},

	/// ------------------------------------

/// - PROC - CARD - PROCESSO - /////////
/// ------------------------------------

	getProcessCard: async (typeProcess, stageProcess, userId) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/cardProcess?${typeProcess}&${stageProcess}&${userId}&token=${token}`);
		const response = await req.json();
		return response
	},

	/// ------------------------------------

	
	/// - PROC - PROCESSOS - ///////////////
	/// ------------------------------------

	getProcessAlert: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/processAlert?token=${token}`)
		const response = await req.json();
		return response;
	},


	getProcessInfoData: async (type) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/processInfoData?type=${type}&token=${token}`)
		const response = await req.json();
		return response;
	},


	getOrderPay: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/orderPay?token=${token}`)
		const response = await req.json();
		return response;
	},


	// getProcessPay: async (type, month) => {
	getProcessPay: async (type) => {
		const token = await localStorage.getItem('SessionID');
		// const req = await fetch(`${GPP_API}/processPay?type=${type}&month=${month}&token=${token}`)
		const req = await fetch(`${GPP_API}/processPay?type=${type}&token=${token}`)
		const response = await req.json();
		return response;
	},


	getArchivedProcess: async (year) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/process?year=${year}&token=${token}`);
		const response = await req.json();
		return response
	},


	getArchivedProcessCriteria: async (item, value) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/process?item=${item}&value=${value}&token=${token}`);
		const response = await req.json();
		return response
	},

	
	postProcess: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/process?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		console.log(response)
		return response;
	},


	delProcess: async (idProc) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/process?id=${idProc}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},


	/// ------------------------------------



	/// - PROC - FASES - FATURAR ///////////
	/// ------------------------------------


	getPhasesProcessBilling: async (processType, processState, phaseStage, userId) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/phasesProcessBilling?${processType}&${processState}&${phaseStage}&${userId}&token=${token}`);
		const response = await req.json();
		return response
	},


	/// ------------------------------------



	/// - PROC - FASES - ///////////////////
	/// ------------------------------------

	getPhase: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/phase?token=${token}`);
		const response = await req.json();
		return response;
	},


	postPhase: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/phase?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delPhase: async (idFase) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/phase?id=${idFase}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - PROC - ATIVIDADES - //////////////
	/// ------------------------------------

	getActivity: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/activity?token=${token}`)
		const response = await req.json();
		return response;
	},


	postActivity: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/activity?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delActivity: async (idAtiv) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/activity?id=${idAtiv}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - PROC - ATIVIDADES-FASE - /////////
	/// ------------------------------------

	getActivityPhase: async (idFase) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/activityPhase?idFase=${idFase}&token=${token}`)
		const response = await req.json();
		return response;
	},


	getActivityPhase2: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/activityPhase2?token=${token}`)
		const response = await req.json();
		return response;
	},


	getPhaseActivity: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/phaseActivity?token=${token}`)
		const response = await req.json();
		return response;
	},


	postPhaseActivity: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/phaseActivity?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delPhaseActivity: async (idAtvdFase) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/phaseActivity?id=${idAtvdFase}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - PROC - FASES-PROCESSO - //////////
	/// ------------------------------------

	getProcessPhase: async (codProcess) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/processPhase?idProcesso=${codProcess}&token=${token}`)
		const response = await req.json();
		return response;
	},


	postProcessPhase: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/processPhase?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delProcessPhase: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/processPhase?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - PROC - CTRL-ATIVIDADES - /////////
	/// ------------------------------------

	getCtrlActivity: async (idFase) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/ctrlActivity?idFase=${idFase}&token=${token}`)
		const response = await req.json();
		return response;
	},


	postCtrlActivity: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/ctrlActivity?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - PROC - COMENTÁRIOS - /////////////
	/// ------------------------------------

	getComment: async (idFaseProc) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/comment?idFaseProc=${idFaseProc}&token=${token}`)
		const response = await req.json();
		return response;
	},


	postComment: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/comment?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - PROC - ADVOGADOS - ///////////////
	/// ------------------------------------

	getLawyer: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/lawyer?token=${token}`);
		const response = await req.json();
		return response;
	},


	postLawyer: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/lawyer?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delLawyer: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/lawyer?id=${id}?token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response ;
	},

	/// ------------------------------------


	/// - PROC - EMPRESAS - ////////////////
	/// ------------------------------------

	getCompany: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/company?token=${token}`);
		const response = await req.json();
		return response ;
	},


	postCompany: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/company?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delCompany: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/company?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - PROC - USUÁRIOS - ////////////////
	/// ------------------------------------

	getUser: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/user?token=${token}`);
		const response = await req.json();
		return response;
	},


	postUser: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/user?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delUser: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/user?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response ;
	},

	/// ------------------------------------




	/// - FIN - BANCOS - ///////////////////
	/// ------------------------------------

	getBank: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/bank?token=${token}`)
		const response = await req.json();
		return response;
	},


	postBank: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/bank?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delBank: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/bank?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();        
		return response;
	},

	/// ------------------------------------


	/// - FIN - TIPOS-CONTA - //////////////
	/// ------------------------------------

	getAccountType: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/accountType?token=${token}`)
		const response = await req.json();
		return response;
	},


	postAccountType: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/accountType?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delAccountType: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/accountType?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();        
		return response;
	},

	/// ------------------------------------


	/// - FIN - CONTA - ////////////////////
	/// ------------------------------------

	getAccount: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/account?token=${token}`)
		const response = await req.json();
		return response;
	},


	postAccount: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/account?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delAccount: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/account?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();        
		return response;
	},

	/// ------------------------------------


	/// - FIN - ESTADOS-LANCAMENTO - ///////
	/// ------------------------------------

	getPostingStatus: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/postingStatus?token=${token}`)
		const response = await req.json();
		return response;
	},


	postPostingStatus: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/postingStatus?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delPostingStatus: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/postingStatus?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - CATEG-RECEBIMENTO - ////////
	/// ------------------------------------

	getReceiptCategory: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/receiptCategory?token=${token}`)
		const response = await req.json();
		return response;
	},


	postReceiptCategory: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/receiptCategory?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delReceiptCategory: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/receiptCategory?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - CATEG-PAGAMENTO - //////////
	/// ------------------------------------

	getPaymentCategory: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/paymentCategory?token=${token}`)
		const response = await req.json();
		return response;
	},


	postPaymentCategory: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/paymentCategory?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delPaymentCategory: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/paymentCategory?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - FORMAS-PAGAMENTO - /////////
	/// ------------------------------------

	getPaymentMethod: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/paymentMethod?token=${token}`)
		const response = await req.json();
		return response;
	},


	postPaymentMethod: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/paymentMethod?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delPaymentMethod: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/paymentMethod?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - PLANO-CONTAS - /////////////
	/// ------------------------------------

	getAccountPlan: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/accountPlan?token=${token}`)
		const response = await req.json();
		return response;
	},


	postAccountPlan: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/accountPlan?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delAccountPlan: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/accountPlan?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - CENTROS-CUSTO - ////////////
	/// ------------------------------------

	getCostCenter: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/costCenter?token=${token}`)
		const response = await req.json();
		return response;
	},


	postCostCenter: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/costCenter?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delCostCenter: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/costCenter?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - TIPOS-ENTIDADE - ///////////
	/// ------------------------------------

	getEntitieType : async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/entitieType?token=${token}`)
		const response = await req.json();
		return response;
	},


	postEntitieType: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/entitieType?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delEntitieType: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/entitieType?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - ENTIDADES - ////////////////
	/// ------------------------------------

	getEntity: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/entity?token=${token}`)
		const response = await req.json();
		return response;
	},


	postEntity: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/entity?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delEntity: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/entity?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},


	/// ------------------------------------


	/// - FIN - TIPOS-CONTRIBUINTE - ///////
	/// ------------------------------------

	getTaxpayerType: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/taxpayerType?token=${token}`)
		const response = await req.json();
		return response;
	},


	postTaxpayerType: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/taxpayerType?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delTaxpayerTypey: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/taxpayerType?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},


	/// ------------------------------------



	/// - FIN - RECEBIMENTOS - /////////////
	/// ------------------------------------

	getReceiptDate: async (startDate, endDate) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/receiptDate?dataInicial=${startDate}&dataFinal=${endDate}&token=${token}`);
		const response = await req.json();
		return response
	},


	postReceipt: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/receipt?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delReceipt: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/receipt?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - PAGAMENTOS - ///////////////
	/// ------------------------------------

	getPaymentDate: async (startDate, endDate) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/paymentDate?dataInicial=${startDate}&dataFinal=${endDate}&token=${token}`);
		const response = await req.json();
		return response
	},


	postPayment: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/payment?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delPayment: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/payment?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------



	/// - FIN - FUNCIONARIOS - /////////////
	/// ------------------------------------

	getEmployee: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/employee?token=${token}`)
		const response = await req.json();
		return response;
	},


	postEmployee: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/employee?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delEmployee: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/employee?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - CLIENTES - /////////////////
	/// ------------------------------------

	getCustomer: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/customer?token=${token}`)
		const response = await req.json();
		return response;
	},


	postCustomer: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/customer?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delCustomer: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/customer?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - FORNECEDORES - /////////////
	/// ------------------------------------

	getSupplier: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/supplier?token=${token}`)
		const response = await req.json();
		return response;
	},


	postSupplier: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/supplier?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delSupplier: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/supplier?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------




	/// - MINHA EMPRESA - //////////////////
	/// ------------------------------------

	getMyCompany: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/myCompany?token=${token}`);
		const response = await req.json();
		return response;
	},


	postMyCompany: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/myCompany?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	/// ------------------------------------




	/// - FIN - INTEGRAÇÃO - ///////////////
	/// ------------------------------------

	getIntegration: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/Integration?token=${token}`)
		const response = await req.json();
		return response;
	},

	
	postIntegration: async (postData) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/Integration?token=${token}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},


	delIntegration: async (id) => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/Integration?id=${id}&token=${token}`, {
			method: 'DELETE'
		});
		const response = await req.json();        
		return response;
	},


	tokenInter: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/inter?nome=inter&token=${token}`)
		const response = await req.json();
		return response;
	},

	/// ------------------------------------



};
