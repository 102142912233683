import { Routes, Route } from "react-router-dom";

import { Layout } from '../container/Layout';
import { Dashboard } from '../pages/Dashboard';
import { Login } from '../pages/Login';
import { Renew } from '../pages/Renew';
import { Processes } from "../pages/Processes";
import { Services } from "../pages/Services";
import { Contracts } from "../pages/Contracts";

/// CADASTROS //////////////////////////////////////////////////////////////////
import { Users } from "../pages/Users";
import { Lawyers } from "../pages/Lawyers";
import { Companies } from "../pages/Companies";
import { Phases } from "../pages/Phases";
import { Activities } from "../pages/Activities";

/// FINANCEIRO /////////////////////////////////////////////////////////////////
import { Receipts } from "../pages/Receipts";
import { Payments } from "../pages/Payments";
import { ReceiptCategories } from "../pages/ReceiptCategories";
import { PaymentCategories } from "../pages/PaymentCategories";
import { Banks}  from "../pages/Banks";
import { Accounts } from "../pages/Accounts";
import { Employees } from "../pages/Employees";
import { Suppliers } from "../pages/Suppliers";

/// OUTROS /////////////////////////////////////////////////////////////////////
import { Integration } from "../pages/Integration";
import { MyCompany } from "../pages/MyCompany";


import { Chat } from "../pages/Chat";
import { Archive } from "../pages/Archive";
import { Template } from "../pages/Template";


export const MainRoute = () => {

    return (
            <Routes>
                <Route path="/" element={<Layout/>}>
                <Route path="Dashboard" element={<Dashboard/>}/>
                <Route path="Processes" element={<Processes/>}/>
                <Route path="Services" element={<Services/>}/>
                <Route path="Contracts" element={<Contracts/>}/>

                {/* /// CADASTROS ////////////////////////////////////////////////////////////////// */}
                <Route path="Users" element={<Users/>}/>
                <Route path="Lawyers" element={<Lawyers/>}/>
                <Route path="Companies" element={<Companies/>}/>
                <Route path="Phases" element={<Phases/>}/>
                <Route path="Activities" element={<Activities/>}/>

                {/* /// FINANCEIRO ///////////////////////////////////////////////////////////////// */}
                <Route path="Receipts" element={<Receipts/>}/>
                <Route path="Payments" element={<Payments/>}/>
                <Route path="ReceiptCategories" element={<ReceiptCategories/>}/>
                <Route path="PaymentCategories" element={<PaymentCategories/>}/>
                <Route path="Banks" element={<Banks/>}/>
                <Route path="Accounts" element={<Accounts/>}/>
                <Route path="Employees" element={<Employees/>}/>
                <Route path="Suppliers" element={<Suppliers/>}/>

                {/* /// OUTROS ///////////////////////////////////////////////////////////////////// */}
                <Route path="Integration" element={<Integration/>}/>
                <Route path="MyCompany" element={<MyCompany/>}/>

                <Route path="Chat" element={<Chat/>}/>
                <Route path="Archive" element={<Archive/>}/>
                </Route>
                <Route path="/Login" element={<Login/>}/>
                <Route path="/Renew" element={<Renew/>}/>
                <Route path="/Template" element={<Template/>}/>
            </Routes>
    );
}
