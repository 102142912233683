import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'

import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import AddIcon from '@mui/icons-material/Add';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import BlockIcon from '@mui/icons-material/Block';
import CategoryIcon from '@mui/icons-material/Category';
import CheckIcon from '@mui/icons-material/Check';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import LoginIcon from '@mui/icons-material/Login';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TaskIcon from '@mui/icons-material/Task';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';



/// DATATABLE ICONS ////////////////////////////////////////////////////////////

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



export const ReceiptCategories = () => {

    const navigate = useNavigate();
    const {register, handleSubmit, formState: { errors }, reset} = useForm({ mode: 'resolver' });

    const { state: user } = useContext(UserContext)

    const [state, setState] = useState('')
    const [list, setList] = useState([]);
    const [modal, setModal] = useState(false)
    const [modalType, setModalType] = useState('')
    const [alertError, setAlertError] = useState('')
    const [selectedKey, setSelectedKey] = useState('')
    const [loading, setLoading] = useState(true)
    const [loadingModal, setLoadingModal] = useState(false)



    /// DATATABLE STATES /////////////////////////////////////////////////////////

    const [filteredList, setFilteredList] = useState([])
    const [order, setOrder] = useState('')
    const [activeOrder, setActiveOrder] = useState('')
    

    
    /// DATATABLE FUNCTIONS ////////////////////////////////////////////////////////

    const handleSearch = (event) => {
      let value = event.target.value.toLowerCase()
      let newArray = list.filter((data) => {
          return data.ctg_rcbmt_descricao.toLowerCase().search(value) !== -1 
      })
      setFilteredList(newArray);
    }

    const OrderClick = (field) => {
      setActiveOrder(field)
      let result = filteredList.sort(function(a, b){
        if (order === 'asc'){
          return a[field] < b[field] ? -1 : true
        } else {
          return a[field] > b[field] ? -1 : true
        }
      })
      order === 'asc' ? setOrder('desc') : setOrder('asc')
      setFilteredList(result)
    }
    
    

    /// FUNCTIONS ////////////////////////////////////////////////////////////////

    const AddClick = () => {
      setModalType(1)
      setModal(true)
    }  
  
    const EditClick = (key) => {
      setSelectedKey([key])
      setModalType(2)
      setModal(true)
    }
  
    const DelClick = (key) => {
      setSelectedKey([key])
      setModalType(3)
      setModal(true)
    }
  
    const ActClick = (key) => {
      setSelectedKey([key])
      setModalType(4)
      setModal(true)
    }
  
  
    const CloseModalClick = () => {
      setLoading(true)
      setSelectedKey('')
      setAlertError('')
      GetReceiptCategory()
      setModal(false)
      setModalType('')
      reset()
      setLoading(false)
    }
  
      const CloseModalClick2 = () => {
      setLoading(true)
      setSelectedKey('')
      setAlertError('')
      setModal(false)
      setModalType('')
      reset()
      setLoading(false)
    }


    const GetReceiptCategory = async () => {
      setLoading(true)
      let res = await Api.getReceiptCategory();
      setList(res)
      setFilteredList(res)
      setLoading(false)
    }


    const onSubmit = async (data) => {
      // console.log("onSubmit > data: ", data)
      switch (modalType) {
        case 1:
          const userAdd = ({
			      ctg_rcbmt_usuario_criacao: user.id,
            ctg_rcbmt_usuario_atualizacao: user.id
          })
          const add = ({...data, ...userAdd})
          let res1 = await Api.postReceiptCategory(add)
          // console.log("addReceiptCategory > res1 : ", res1)
        break;

        case 2:
          const userUpd = ({
            ctg_rcbmt_usuario_atualizacao: user.id
          })
          const upd = ({...data, ...userUpd})
          let res2 = await Api.postReceiptCategory(upd)
          // console.log("upgReceiptCategory > res2 : ", res2)
        break;

        default:
        break;
      }
      CloseModalClick()
    };


    const DelReceiptCategory = async (id) => {
      let res = await Api.delReceiptCategory(id);
      GetReceiptCategory()
    }


    const ActReceiptCategory = async (id, estado) => {
      const data = ({
        ctg_rcbmt_id: id, 
        ctg_rcbmt_estado: estado,
        ctg_rcbmt_usuario_atualizacao: user.id
      })
      let res = await Api.postReceiptCategory(data)
      // console.log("ActReceiptCategory > res : ", res)
      GetReceiptCategory()
    }




/// DETECTA A TECLA ESCAPE (ESC) ///////////////////////////////////////////////

document.onkeydown = function (e) {
  if (e.key === 'Escape') {
    CloseModalClick();
  }
}



/// USE EFECT //////////////////////////////////////////////////////////////////

  useEffect(()=>{
    GetReceiptCategory();
  }, [state]);


/// CONSOLE LOG ////////////////////////////////////////////////////////////////




    return (
        <G.Container>

          <G.Header>

            <G.HeaderTitleArea style={{ width: '320px' }}>
              <LoginIcon style={{ fontSize: '32px',margin: '0px 10px 0px 0px' }}/>
              <G.MainTitle>Categorias - Entrada</G.MainTitle>
            </G.HeaderTitleArea>
            <G.HeaderTitleArea style={{ width: '240px' }}>
              
            </G.HeaderTitleArea>
            <G.HeaderTitleArea style={{ width: '280px', justifyContent: 'center' }}>
              <G.SearchArea style={{ height: '40px', color: '#444', justifyContent: 'space-between' }}>
                <G.SearchIconArea>
                  <SearchIcon />
                </G.SearchIconArea>
                <G.SearchInput id='search' onChange={(event) => handleSearch(event)} />
              </G.SearchArea>        
            </G.HeaderTitleArea>
            <G.HeaderTitleArea style={{ width: '120px', justifyContent: 'end' }}>
              <G.Button style={{ backgroundColor: '#2E8B57', justifyContent: 'space-around' }} onClick={AddClick}>
                <AddIcon fontSize='small'/>
                {'Adicionar'}
              </G.Button>
            </G.HeaderTitleArea>

          </G.Header>

          <G.Body>

            {loading &&  
              <G.Logo src={"/loading.svg"} />
            }

            {(!loading && filteredList.length === 0) &&
              <G.OptionsArea style={{justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column'}}>
                <ErrorOutlineRoundedIcon style={{fontSize: '80px', color: '#aaa' }}/>
                <G.MainTitle style={{fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
                  Não existem itens para a seleção atual! 
                </G.MainTitle>
              </G.OptionsArea>
            }

            {(!loading && filteredList.length > 0)  && 
              <>
                <G.Box>
                  <G.TableHeader>
                    <G.OptionsArea style={{ display: 'flex', flex: '1', justifyContent: 'space-around', padding: '0px' }}>
                      <G.FieldArea size={'48px'} style={{ padding: '0px', justifyContent: 'center' }}><G.DistacInfoText></G.DistacInfoText></G.FieldArea>
                      <G.FieldArea size={'350px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('ctg_rcbmt_descricao')}><G.DistacInfoText>Nome da Categoria</G.DistacInfoText>
                        {(activeOrder === '' || activeOrder === 'ctg_rcbmt_descricao') &&
                          <>
                            {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                          </>
                        }
                      </G.FieldArea>
                      <G.FieldArea size={'380px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('ctg_rcbmt_observacao')}><G.DistacInfoText>Observações</G.DistacInfoText>
                        {(activeOrder === '' || activeOrder === 'ctg_rcbmt_observacao') &&
                          <>
                            {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                          </>
                        }
                      </G.FieldArea>
                      <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}><G.DistacInfoText>Ações</G.DistacInfoText></G.FieldArea>
                    </G.OptionsArea>
                  </G.TableHeader>

                  {(loading === true && filteredList.length === 0 ) &&  
                    <G.Loading2 src={"/loading.svg"} />
                  }

                  <G.TableBody style={{ justifyContent: 'start', marginTop: '0px'}}>
                    {(loading === false && filteredList.length === 0) && 
                      <G.Text style={{ height: '40px', justifyContent: 'center', alignItems: 'center', marginTop: '20px', fontSize: '13px' }}>
                        *** Não existem itens a exibir. ***
                      </G.Text>
                    }

                    {(loading === false && filteredList.length !== 0) &&
                      <>
                        {filteredList.map((item, key) => (
                          <G.TableArea key={key} style={{ justifyContent: 'space-around'}}>
                            {/* <G.FieldArea size={'48px'} style={{ padding: '0px', justifyContent: 'center' }}><G.Text><C.ContactTypeIconArea style={{ border:  item.ctg_rcbmt_estado === 1 ? '2px solid #2E8B57' : '2px solid #E95839' }}> */}
                            <G.FieldArea size={'48px'} style={{ padding: '0px', justifyContent: 'center' }}><G.Text><C.ContactTypeIconArea style={{ border: 'var(--circular-border)' }}>
                              <FactCheckIcon style={{fontSize: '22px', color: 'var(--icon-color)'}}/></C.ContactTypeIconArea></G.Text>
                            </G.FieldArea>
                            <G.FieldArea size={'350px'} style={{ justifyContent: 'start' }}><G.Text>{item.ctg_rcbmt_descricao}</G.Text></G.FieldArea>
                            <G.FieldArea size={'380px'} style={{ justifyContent: 'start' }}><G.Text>{item.ctg_rcbmt_observacao}</G.Text></G.FieldArea>
                            <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}>
                              <G.IconButton onClick={() => EditClick(key)} style={{ backgroundColor: '#1E90FF', margin: '0px 1px' }}><EditIcon fontSize="small" /></G.IconButton>
                              {/* <G.IconButton onClick={() => DelReceiptCategory(item.ctg_rcbmt_id, item.ctg_rcbmt_estado === 0 ? 1 : 0)} style={{ backgroundColor: item.ctg_rcbmt_estado === 0 ? '#2E8B57' : '#555', margin: '0px 1px' }}>{item.ctg_rcbmt_estado === 0 ? <CheckIcon fontSize="small"/> : <BlockIcon fontSize="small"/>}</G.IconButton> */}
                              {/* <G.IconButton onClick={() => ActClick(key)} style={{ backgroundColor: item.ctg_rcbmt_estado === 0 ? '#2E8B57' : '#555', margin: '0px 1px' }}>{item.ctg_rcbmt_estado === 0 ? <CheckIcon fontSize="small"/> : item.ctg_rcbmt_estado === 1 ? <BlockIcon fontSize="small"/> : <PriorityHighIcon fontSize="small"/>}</G.IconButton> */}
                              <G.IconButton onClick={() => DelClick(key)} style={{ backgroundColor: '#E95839', margin: '0px 1px'}}><DeleteIcon fontSize="small"/> </G.IconButton>
                            </G.FieldArea>
                        </G.TableArea>
                        ))}
                      </>
                    }
                  </G.TableBody>
                </G.Box>
              </>
            }

          </G.Body>

          <G.Footer style={{ color: '#333', fontSize: '14px' }}>
            {/* <p> Copyright <CopyrightIcon style={{ fontSize: '14px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
          </G.Footer>

          {modal !== false &&
            <M.ModalContainer>
              <M.ModalContentSmall>

                <M.ModalHeader style={{ backgroundColor: modalType === 1 ? '#BDF5D6' : modalType === 2 ? '#C8E4FF' : modalType === 3 ? '#FCAC9B' : (filteredList[selectedKey].ctg_rcbmt_estado === 0 ? '#BDF5D6' : '#ccc'), justifyContent: 'space-between', bordeRadius: '15px' }}>
                  <C.ModalTitle>{modalType === 1 ? 'Adicionar Categoria Entrada' : modalType === 2 ? 'Editar Categoria Entrada' : modalType === 3 ? 'Excluir Categoria Entrada' : (filteredList[selectedKey].ctg_rcbmt_estado === 0 ? 'Ativar Categoria Entrada' : 'Desativar Categoria Entrada')}</C.ModalTitle>
                  <G.InfoArea>
                    <HighlightOffIcon fontSize="large" style={{ cursor: 'pointer', color: '#333' }} onClick={CloseModalClick} />
                  </G.InfoArea>
                </M.ModalHeader>
                <M.ModalBody style={{ flexDirection: 'column'}}>

                  {(modalType === 2 || modalType === 3) && 
                    <G.FormInput style={{ width: '80px'}} type="hidden" defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].ctg_rcbmt_id} {...register("ctg_rcbmt_id")} />
                  }

                  {(modalType === 1 || modalType === 2) &&
                    <G.BoxBodyScroll style={{ flexDirection: 'column'}}>

                      <G.FormInputArea>
                        <G.FormInputLabel>
                          Nome da Categoria
                        </G.FormInputLabel>
                        <G.FormInput autoFocus defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].ctg_rcbmt_descricao} {...register("ctg_rcbmt_descricao")} />
                      </G.FormInputArea>

                      <G.FieldArea style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: '20px', marginBottom: '10px' }}>
                        <G.FormInputLabel style={{ border: 'none', width: '450px', backgroundColor: '#f0f0f0', borderRadius: '5px 5px 0px 0px', borderBottom: '1px solid #E0E0E0' }}>
                          Observação
                        </G.FormInputLabel>
                        <G.TextArea {...register("ctg_rcbmt_observacao", { setValueAs: v => v === '' ? null : v })} 
                              style={{ width: '430px', height: '40px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '0px 0px 5px 5px', fontFamily: 'inherit', color: '#222', fontSize: '14px' }}
                        >
                          {modalType === 1 ? '' :  filteredList[selectedKey].ctg_rcbmt_observacao}
                        </G.TextArea>
                      </G.FieldArea>

                    </G.BoxBodyScroll>
                  }
                </M.ModalBody>

                {(modalType === 3) && 
                  <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                    <G.Text style={{ margin: '10px 0px' }}>
                      Deseja excluir a Categoria Entrada:
                    </G.Text>
                    <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                      <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                        {filteredList[selectedKey].ctg_rcbmt_descricao}
                      </G.FormInputLabel>
                    </G.FormInputArea>
                  </M.ModalBody>
                }

                {(modalType === 4) && 
                  <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                    <G.Text style={{ margin: '10px 0px' }}>
                      Deseja {filteredList[selectedKey].ctg_rcbmt_estado === 0 ? 'ativar' : 'desativar'} a Categoria Entrada:
                    </G.Text>
                    <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                      <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                        {filteredList[selectedKey].ctg_rcbmt_descricao}
                      </G.FormInputLabel>
                    </G.FormInputArea>
                  </M.ModalBody>
                }

                <M.ModalFooter style={{ flexDirection: 'row', justifyContent: 'center' }}>
                  {alertError !== '' &&
                    <C.Alert><WarningIcon style={{marginRight: '10px', fontSize: '30px', color: '#333' }} />{alertError}</C.Alert>
                  }
                  
                  {(modalType === 1 || modalType === 2) &&
                    // <G.Button onClick={SendReceiptCategory} style={{backgroundColor: '#333', width: '120px'}}>Enviar</G.Button>
                    // <G.Button style={{backgroundColor: '#333'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                    <>
                      <G.Button style={{backgroundColor: '#E95839'}} onClick={() => CloseModalClick2()}>Fechar</G.Button>
                      <G.Button style={{backgroundColor: '#2E8B57'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                    </>
                  }

                  {modalType === 3 && 
                    <>
                      <G.Button style={{backgroundColor: '#E95839', width: '120px'}} onClick={() => CloseModalClick2()}>Não</G.Button>
                      <G.Button style={{backgroundColor: '#2E8B57', width: '120px'}} onClick={() => DelReceiptCategory(filteredList[selectedKey].ctg_rcbmt_id) + CloseModalClick()}>Sim</G.Button>
                    </>
                  }
                  {modalType === 4 && 
                    <>
                      <G.Button style={{ backgroundColor: '#E95839', width: '120px' }} onClick={() => CloseModalClick2()}>Não</G.Button>
                      <G.Button style={{ backgroundColor: '#2E8B57', width: '120px' }} onClick={() => ActReceiptCategory(filteredList[selectedKey].ctg_rcbmt_id, filteredList[selectedKey].ctg_rcbmt_estado === 0 ? 1 : 0) + CloseModalClick2()}>Sim</G.Button>
                    </>
                  }
                </M.ModalFooter>

              </M.ModalContentSmall>
            </M.ModalContainer>
            }
        </G.Container>

    );
}
