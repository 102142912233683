import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { mask, unMask } from 'remask';
import moment from "moment";



import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'



import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';


import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import CloseIcon from '@mui/icons-material/Close';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import SearchIcon from '@mui/icons-material/Search';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import TaskIcon from '@mui/icons-material/Task';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';



export const Archive = () => {

    const navigate = useNavigate();

    const {register, handleSubmit, reset, formState: { errors }} = useForm({ mode: 'all' });

    const { state: user } = useContext(UserContext);

    const [state, setState] = useState('')
    const [typeProcess, setTypeProcess] = useState('')

    const [list, setList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [fasesProcessoList, setFasesProcessoList] = useState([]);
    const [selectedProcess, setSelectedProcess] = useState('');

    const [switchButton, setSwitchButton] = useState(false);
    const [dateFilter, setDateFilter] = useState((new Date().getFullYear()).toString());
    const [empty, setEmpty] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [receiptEntry, setReceiptEntry] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState('');
    const [acts, setActs] = useState('');
    const [actsDetails, setActsDetails] = useState('');
    const [actsFull, setActsFull] = useState('');

    const [addProcessPhase, setAddProcessPhase] = useState(false);
    const [addProcessPhaseDate, setAddProcessPhaseDate] = useState('');

    const [step, setStep] = useState(1);
    const [prevValue, setPrevValue] = useState('');

    const [valueAPIFilter, setValueAPIFilter] = useState('Selecione');
    const [filterCriteria, setFilterCriteria] = useState('');

    const [comments, setComments] = useState('');
    const [selectedActs, setSelectedActs] = useState([]);
    const [comentarios, setComentarios] = useState([]);
    const [atividades, setAtividades] = useState([]);

    const [company, setCompany] = useState([]);
    const [lawyer, setLawyer] = useState([]);
    const [users, setUsers] = useState([]);
    const [phase, setPhase] = useState([]);
    const [selectedPhase, setSelectedPhase] = useState([]);
    const [activityPhase, setActivityPhase] = useState([]);
    const [receiptEntryVerify, setReceiptEntryVerify] = useState(false);
    

    const [faseEntrada, setFaseEntrada] = useState('');
    const [fasePrazo, setFasePrazo] = useState('');

    const [payValue, setPayValue] = useState('');
    const [payDate, setPayDate] = useState('');
    const [payAccounts, setPayAccounts] = useState([]);
    const [selectedPayAccount, setSelectedPayAccount] = useState('');
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [entityType, setEntityType] = useState([]);
    const [entity, setEntity] = useState([]);
    const [entityId, setEntityId] = useState('');
    const [entityName, setEntityName] = useState('');

    const [isData, setIsData] = useState(false);

    const [stateErrors, setStateErros] = useState(false);


    const now = new Date();
    const day = now.getDate();
    const month = now.getMonth() + 1;
    const year = now.getFullYear();
    const today = moment().format("YYYY-MM-DD")



/// PROCESS NUMBER MASK ////////////////////////////////////////////////////////

  const [processNumber, setProcessNumber] = useState('')

  const onChange = ev => {
    
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['9999999-99.9999.9.99.9999']);

    setProcessNumber(unMask(maskedValue));


  }

////////////////////////////////////////////////////////////////////////////////



  const GoClick = () => {
    navigate('/Processes');
  }


  const loadBases =  async () => {
    let res3 = await Api.getAccount()
    setPayAccounts(res3)
    let res5 = await Api.getPaymentMethod()
    setPaymentMethod(res5)
    let res7 = await Api.getEntity()
    setEntityType(res7)
  }


  const handleSearch = (event) => {
    if (event.target.value === '') {
      setIsData(false);
    }
    let value = event.target.value.toLowerCase();
    let result = [];
    result = list.filter((data) => {
      return data.proc_reclamante.toLowerCase().search(value) !== -1
      || data.proc_reclamada.toLowerCase().search(value) !== -1 
      || data.proc_identificacao.toLowerCase().search(value) !== -1 
      || data.proc_codigo.toLowerCase().search(value) !== -1 
      || data.proc_id.toString().search(value) !== -1; 
    });
    if (event.target.value !== '') {
      setIsData(true)
    }
    setFilteredList(result);
  }

  const InputClear = () => {
    const clear = document.querySelector('#search')
    clear.value = '';
    GetArchivedProcess()
  }

  
  const TypeProcessFilter = (typeProcess) => {
    // document.getElementById('search').value = '';
    // setFilteredList([])
    setTypeProcess(typeProcess)
  }
  

  const GetAtividades = async (idFase) => {
    let res = await Api.getCtrlActivity(idFase);
    if (res) {
      setAtividades(res)
    } else {}
      setActsFull(true);
  }

  const ProcessDetails = async (key, codProcess) => {
    setLoadingModal(false)
    setSelectedProcess(key)
    setProcessNumber(filteredList[key].proc_numero);
    let res1 = await Api.getCompany()
    setCompany(res1)
    let res2 = await Api.getLawyer()
    setLawyer(res2)
    let res3 = await Api.getUser()
    setUsers(res3)
    let res4 = await Api.getPhase()
    setPhase(res4)
    let res5 = await Api.getProcessPhase(codProcess);
    setFasesProcessoList(res5.sort(function(a, b){return new Date(b.fase_proc_data_entrada) - new Date(a.fase_proc_data_entrada)}))
    setModal(true)
  }


  const Receipts = async (key, codProcess) => {
    setLoadingModal(false)
    setSelectedProcess(key)
    setProcessNumber(filteredList[key].proc_numero);
    let res1 = await Api.getCompany()
    setCompany(res1)
    let res2 = await Api.getLawyer()
    setLawyer(res2)
    let res3 = await Api.getUser()
    setUsers(res3)
    let res4 = await Api.getPhase()
    setPhase(res4)
    let res5 = await Api.getProcessPhase(codProcess);
    setFasesProcessoList(res5.sort(function(a, b){return new Date(b.fase_proc_data_entrada) - new Date(a.fase_proc_data_entrada)}))
    setReceiptEntry(true)
  }


  const GetSelect =  async (option) => {
    setEntityId(option)
    switch (option) {
      case '1':
        let res1 = await Api.getEmployee()
        setEntity(res1)
        break;
      case '2':
        let res2 = await Api.getSupplier()
        setEntity(res2)
        break;
      case '3':
        let res3 = await Api.getCustomer()
        setEntity(res3)
        break;
      case '4':
        let res4 = await Api.getTransportCompany()
        setEntity(res4)
        break;
      case '5':
        let res5 = await Api.getCompany()
        setEntity(res5)
        break;
      case '6':
        let res6 = await Api.getLawyer()
        setEntity(res6)
        break;
      case '7':
        let res7 = await Api.getPhase()
        setPhase(res7)
        break;
      default:
        break;
    }
  }

  // const PayAccounts = async () => {
  //   let res = await Api.getAccounts()
  //   setPayAccounts(res)
  // }

  // const GetSelect =  async (value) => {
  //   let res = await Api.getPhase()
  //   setPhase(res)
  // }
  
  const GetActivityPhase =  async (value) => {
    setSelectedPhase(value)
    let res = await Api.getActivityPhase(value)
    setActivityPhase(res)
  }

  const AddProcessPhase = async () => {
    let res = await Api.postProcessPhase(
      {
        "fase_proc_fase_id": selectedPhase,
        "fase_proc_processo_id": filteredList[selectedProcess].proc_id,
        "fase_proc_data_entrada": faseEntrada,
        "fase_proc_data_pazo": fasePrazo,
        "fase_proc_usuario_atualizacao": user.id
      })
    let processPhase = res.toString()
    let atvsData = activityPhase.map(i => ({...i, ctrl_atvd_fase_id: processPhase})) 
    let res2 = await Api.postCtrlActivity(atvsData)
    let res3 = await Api.postProcess({
        "proc_etapa_id": 1, // Etapa: 1 – Aberto.
        "proc_estado_id": 1, // Estado: 1 – Ativo.
        "proc_id": filteredList[selectedProcess].proc_id,
        "proc_atualizacao_usuario": user.id
      })
    setAddProcessPhase('');
    setModal(false);
    setActivityPhase('');
    GetArchivedProcess();
  };
  
  const CloseModalClick = (value) => {
    setModal(false)
    setReceiptEntry(false)
    setSelectedProcess('')
    setProcessNumber('')
    setAddProcessPhase('')
    if (value !== '0') {
      GetArchivedProcess();
    }
  }

  const RefreshClick = (Year) => {
    document.getElementById('search').value = '';
    setFilteredList([])
    setDateFilter(Year)
  }

  const GetArchivedProcess = async (value) => {
    setEmpty(false)
    setFilteredList([])
    if (valueAPIFilter === 'Selecione'){
      document.getElementById('search').value = '';
      setLoading(true)
      let res = await Api.getArchivedProcess(dateFilter, '');
      let result = res.sort(function(a, b){return new Date(b.proc_data_entrada) - new Date(a.proc_data_entrada)})
      if (res) {
        setEmpty(false)
        setList(result)
        setFilteredList(result)
      } else {
        setEmpty(true)
      }
      setLoading(false);
    } else {
      setLoading(true)
      let res = await Api.getArchivedProcessCriteria(valueAPIFilter, value ? value : filterCriteria ? filterCriteria : null);
      let result = res.sort(function(a, b){return new Date(b.proc_data_entrada) - new Date(a.proc_data_entrada)})
      if (res) {
        setEmpty(false)
        setList(result)
        setFilteredList(result)
      } else {
        setEmpty(true)
      }
      setLoading(false);
    }
  }

  const PayClickConfirm = async (key) => {
    let res1 = await Api.postReceipt(
      {
        rcbmt_data_vencimento: payDate,
        rcbmt_data_compensacao: payDate,
        rcbmt_descricao: 'Recebimentos – Processos de Perícia',
        rcbmt_entidade_nome: entityName,
        rcbmt_entidade_id: entityId,
        rcbmt_forma_pagamento: 9, // Forma de Pagamento: 9 – Transferência Bancária. 
        rcbmt_valor_bruto: payValue,
        rcbmt_categoria: 1, // Categoria: 1 – Processos – Perícia.
        rcbmt_estado: 2, // Estado: 2 – Confirmado.
        rcbmt_conta: selectedPayAccount,
        rcbmt_observacao: 'Processo' + ': ' + filteredList[key].proc_id + ' | ' + filteredList[key].proc_identificacao,
        rcbmt_usuario_criacao: user.id,
        rcbmt_usuario_atualizacao: user.id
      })
    let res3 = await Api.postProcess({
      "proc_id": filteredList[key].proc_id,
      "proc_recebimento_valor": (Number(filteredList[key].proc_recebimento_valor) + payValue),
    })
    setReceiptEntry(false)
    setStep(1)
  }

  const ReceiptEntryPopUpClose = () => {
    setReceiptEntry(false);
    setPayValue('');
    setPayDate('');
    setEntityId('');
    setEntityName('');
    setSelectedPayAccount('');
    setStep(1)
  }

  const ReceiptEntryVerify = (payValue, payDate, entityId, entityName, selectedPayAccount) => {
    if (
      // (payValue && payDate && entityId && entityName && selectedPayAccount)
      (payValue.toString().length !== 0 && payDate.toString().length !== 0 && entityId.toString().length !== 0 && entityName.toString().length !== 0 && selectedPayAccount.toString().length !== 0)
    ) {
      setReceiptEntryVerify(true);
    } else {
      setReceiptEntryVerify(false);
    }
  }



  const onSubmit = async (data, type) => {
    if (processNumber === null || processNumber === '' ) {
      setStateErros(true)
    }
    else {
      setStateErros(false)
      data["proc_numero"] = processNumber    
      let res = await Api.postProcess(data)
      type === 1 ? setAddProcessPhase(true) : CloseModalClick() 
    }
  }



/// Detecta a tecla Escape (Esc) e fecha o Modal.
  // document.onkeydown = function (e) {
  //   if (e.key === 'Escape') {
  //     setModal(false);
  //     setAddProcessPhase('');
  //     setActivityPhase('');
  //     CloseModalClick();
  //     GetArchivedProcess();
  //     setReceiptEntry(false);
  //     InputClear();
  //     setIsData(false);
  //   }
  // }



/// USEEFECT ///////////////////////////
  useEffect(() => {
    reset();
  }, [modal]);

  useEffect(()=>{
    GetArchivedProcess();
    //PayAccounts();
  }, [dateFilter, switchButton, valueAPIFilter]);



/// CONSOLE ////////////////////////////






/// RETURN /////////////////////////////

  return (
    <C.Container>

      <C.Header>

        <C.TitleArea>
          <ArchiveIcon style={{ fontSize: '32px', margin: '0px 10px 0px 0px' }}/>
          <h2>Arquivados</h2>
        </C.TitleArea>

        <G.OptionsArea>

          {/* <G.StateArea style={{ height: '40px', padding: '2px 8px', margin: '0px 4px' }}>
            <TaskIcon onClick={() => TypeProcessFilter('1')} style={{ fontSize: typeProcess === '1' ? '36px' : '30px', outline: 'none', color: '#F15068', cursor: 'pointer' }}/>
            <TaskIcon onClick={() => TypeProcessFilter('2')} style={{ fontSize: typeProcess === '2' ? '36px' : '30px', outline: 'none', color: '#51B476', cursor: 'pointer' }}/>
            <TaskIcon onClick={() => TypeProcessFilter('3')} style={{ fontSize: typeProcess === '3' ? '36px' : '30px', outline: 'none', color: '#84b6f4', cursor: 'pointer' }}/>
            {typeProcess !== '' &&
              <CloseRoundedIcon onClick={() => TypeProcessFilter('')} style={{ cursor: 'pointer'}}/>
            }
          </G.StateArea>  */}

          <G.FormInputArea style={{ alignItems: 'center', margin: '2px 24px 2px 2px' }}>
            <G.FormSelect 
              id='searchType' 
              value={valueAPIFilter === 'Selecione' ? 'Selecione' : valueAPIFilter} 
              onChange={(e) => setValueAPIFilter(e.target.value)} 
              style={{ width: '200px', height: '38px', padding: '8px', borderRadius: '8px', border: '1px solid #E5E5E5', backgroundColor: '#f0f0f0' }}
            >
              <option value='' default>Selecione</option>
              <option value={'proc_id'}>ID</option>
              <option value={'proc_codigo'}>Código</option>
              <option value={'proc_identificacao'}>Identificação</option>
              <option value={'proc_numero'}>Número</option>
              <option value={'proc_reclamante'}>Reclamante</option>
              <option value={'proc_reclamada'}>Reclamada</option>
            </G.FormSelect>
            {valueAPIFilter !== 'Selecione' && 
              <CloseRoundedIcon 
                // fontSize='small' 
                onClick={() => setValueAPIFilter('Selecione') + setDateFilter((year).toString())} 
                style={{ padding: '2px', margin: '0px 0px 0px -44px', fontSize: '16px', cursor: 'pointer' }} 
              />
            }

          </G.FormInputArea>
        
          <C.SearchArea style={{ marginRight: '0px' }}>

            <G.SearchIconArea>
            {!isData && 
              <SearchIcon />
            }
            {isData && 
              <CloseRoundedIcon 
                // fontSize='small' 
                onClick={() => setIsData(false) + InputClear()} 
                style={{ cursor: 'pointer' }} 
              />
            }
            </G.SearchIconArea>
            {valueAPIFilter === 'Selecione' &&
              <G.SearchInput id='search' onChange={(event) => handleSearch(event)}></G.SearchInput>
            }
            {valueAPIFilter !== 'Selecione' &&
              <>
                <G.SearchInput 
                  id='searchAPI' 
                  onMouseLeave={(e) => setFilterCriteria(e.target.value)}
                  onKeyPress={event => {if (event.key === 'Enter') {GetArchivedProcess(event.target.value)}}}
                >
                </G.SearchInput>
                <G.PlayIconButton style={{ width: '32px', marginLeft: '6px' }}>
                  <PlayArrowRoundedIcon onClick={() => GetArchivedProcess()} style={{ fontSize: '32px' }} />
                </G.PlayIconButton>
              </>
            }
          </C.SearchArea>
        
          <G.SwitchArea style={{ minWidth: '64px', width: 'auto', justifyContent: 'flex-end', marginRight: '4px', marginLeft: '12px', padding: '0px 4px 0px 8px' }}>
            <G.Button onClick={GoClick} style={{ width: '58px', justifyContent: 'space-around', backgroundColor: '#333' }}>
              {/* <TaskIcon/> */}
              Ativos
              <OpenInNewIcon style={{ marginLeft: '2px' }} fontSize="small" />
            </G.Button>
          </G.SwitchArea>

        </G.OptionsArea>               

      </C.Header>

      <C.StateArea style={{ borderRadius: '0px', borderBottom: '1px solid #ccc', backgroundColor: '#fff' }}>
        <ArchiveIcon fontSize="large" style={{ marginRight: '4px' }}/>
        <C.CardHeaderTitle style={{ margin: '4px 8px' }}><strong>{filteredList.length}</strong><br />{'Processos'}</C.CardHeaderTitle>
        {valueAPIFilter === 'Selecione' &&
          <>
            <G.SearchInput 
              value={dateFilter} 
              onChange={e => setDateFilter(e.target.value)} 
              style={{ display: 'flex', width: '36px', height: '40px', justifyItems: 'center', alignItems: 'center', border: '1px solid #ffb400', borderRadius: '6px', margin: "6px 8px 4px 8px", padding: '0px 16px', backgroundColor: '#f9f9f9', color: '#000', fontSize: '13px', fontWeight: 'bold' }}
            />

            {(dateFilter !== (year).toString() && valueAPIFilter === 'Selecione') && 
              <G.PlayIconButton style={{ width: '14px', height: '14px', borderRadius: '4px', border: '1px solid #ddd', margin: '4px 4px 4px 1px' }}>
                <CloseRoundedIcon onClick={() => setDateFilter((year).toString())} style={{ fontSize: '12px' }} />
              </G.PlayIconButton>
            }

            <C.Button 
              onClick={() => RefreshClick(dateFilter === (year - 1).toString() ? (year).toString() : (year - 1).toString())} 
              style={{ width: '44px', height: '22px', padding: '6px 10px', backgroundColor: dateFilter === (year - 1).toString() ? '#ffb400' : '#f9f9f9', color: '#000', fontWeight: dateFilter === (year - 1).toString() ? 'bold' : 'normal' }}
            >
              {year - 1}
            </C.Button>
            <C.Button 
              onClick={() => RefreshClick(dateFilter === (year - 2).toString() ? (year).toString() : (year - 2).toString())} 
              style={{ width: '44px', height: '22px', padding: '6px 10px', backgroundColor: dateFilter === (year - 2).toString() ? '#ffb400' : '#f9f9f9', color: '#000', fontWeight: dateFilter === (year - 2).toString() ? 'bold' : 'normal' }}
            >
              {year - 2}
            </C.Button>
            <C.Button 
              onClick={() => RefreshClick(dateFilter === (year - 3).toString() ? (year).toString() : (year - 3).toString())} 
              style={{ width: '44px', height: '22px', padding: '6px 10px', backgroundColor: dateFilter === (year - 3).toString() ? '#ffb400' : '#f9f9f9', color: '#000', fontWeight: dateFilter === (year - 3).toString() ? 'bold' : 'normal' }}
            >
              {year - 3}
            </C.Button>
            <C.Button 
              onClick={() => RefreshClick(dateFilter === (year - 4).toString() ? (year).toString() : (year - 4).toString())} 
              style={{ width: '44px', height: '22px', padding: '6px 10px', backgroundColor: dateFilter === (year - 4).toString() ? '#ffb400' : '#f9f9f9', color: '#000', fontWeight: dateFilter === (year - 4).toString() ? 'bold' : 'normal' }}
            >
              {year - 4}
            </C.Button>
            <C.Button 
              onClick={() => RefreshClick(dateFilter === (year - 5).toString() ? (year).toString() : (year - 5).toString())} 
              style={{ width: '44px', height: '22px', padding: '6px 10px', backgroundColor: dateFilter === (year - 5).toString() ? '#ffb400' : '#f9f9f9', color: '#000', fontWeight: dateFilter === (year - 5).toString() ? 'bold' : 'normal' }}
            >
              {year - 5}
            </C.Button>
          </>
        }

      </C.StateArea>

      <C.Body style={{ width: '1120px' }}>

        {empty &&
          <C.CardHeaderTitle style={{ fontSize: '15px', paddingBottom: '8px', borderBottom: '1px solid #333', marginTop: '150px' }}>
              Não existem Processos para esse filtro dentro do ano de <strong>{dateFilter}</strong>
          </C.CardHeaderTitle>
        }

        <G.Card style={{ width: '98%', justifyContent: 'center', alignItems: 'center', boxShadow: 'none' }}>

          {loading &&  
            <G.Logo src={"/loading.svg"} />
          }

          {/* TABELA DE VISUALIZAÇÃO DOS PROCESSOS ARQUIVADOS */}
          {/* ADICIONAR NOVOS VALORES RECEBIDOS DE PROCESSOS DE PREÍCIA */}
          {filteredList.map((item, key)=>(
            <G.TableArea  key={key} style={{ width: '98%', padding: '4px 2px', border: '1px solid #e0e0e0', borderRadius: '6px', margin: '4px' }}>

              {/* ÍCONE SINALIZADOR RECEBIMENTOS */}
              <C.CardBodyStatusText style={{ display: 'flex', width: '48px', height: '30px', justifyContent: 'center', alignItems: 'center', margin: '2px 4px' }}>
                {(item.proc_tipo_proc_id === 1 || item.proc_tipo_proc_id === 2) && 
                  <G.Button 
                    onClick={() => setReceiptEntry(key) + loadBases()}
                    style={{ 
                      display: 'flex',
                      width: '28px', 
                      height: '24px',
                      justifyContent: 'center', 
                      alignItems: 'center', 
                      padding: '0px', 
                      borderRadius: '4px', 
                      cursor: 'pointer', 
                      backgroundColor: item.proc_homologado_valor === '0.00' ? '#555' 
                      : (item.proc_recebimento_valor ==='0.00' && item.proc_homologado_valor !== '0.00') ? '#E95839' 
                      : (item.proc_homologado_valor >= item.proc_recebimento_valor) && item.proc_homologado_valor !== '0.00' ? '#FCC703' 
                      : (item.proc_recebimento_valor >= item.proc_homologado_valor) && item.proc_homologado_valor !== '0.00' ? '#2E8B57' 
                      : 'blue'
                    }}
                  >
                    <AttachMoneyIcon style={{ fontSize: '18px', color: '#FFF' }} />
                  </G.Button>
                }

              
              </C.CardBodyStatusText>

              {/* ADICIONA NOVO VALOR RECEBIDO - PROCESSO PERÍCIA */}
              <div style={{ maxHeight: '5px', maxWidth: '5px' }}>
                {receiptEntry === key &&

                  <G.PopUpArea key={key} style={{ width: step === 1 ? '250px' : '700px', margin: '0px' }}>

                    <G.PopUpCloseArea onClick={() => ReceiptEntryPopUpClose()}>
                      <CloseIcon style={{ fontSize: '20px' }}/>
                    </G.PopUpCloseArea>

                    {step === 1 && 
                      <G.BoxBody style={{ flexDirection: 'column', gap: '4px' }}>
                        <G.DistacInfoText style={{ backgroundColor: '#777', width: '200px', padding: '10px', borderRadius: '5px', color: '#fff', justifyContent: 'space-between', marginBottom: '5px' }}>
                          <div>honorário: </div> {parseFloat(filteredList[key].proc_honorario_valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}
                        </G.DistacInfoText>
                        <G.DistacInfoText style={{ backgroundColor: '#333', width: '200px', padding: '10px', borderRadius: '5px', color: '#fff', justifyContent: 'space-between', marginBottom: '5px' }}>
                        <div>Homologado:</div> {parseFloat(filteredList[key].proc_homologado_valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}
                        </G.DistacInfoText>
                        <G.DistacInfoText style={{ backgroundColor: '#1E90FF', width: '200px', padding: '10px', borderRadius: '5px', color: '#fff', justifyContent: 'space-between', marginBottom: '5px' }}>
                        <div>Recebido:</div>  {parseFloat(filteredList[key].proc_recebimento_valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}
                        </G.DistacInfoText>
                        <G.DistacInfoText style={{ backgroundColor: 'purple', width: '200px', padding: '10px', borderRadius: '5px', color: '#fff', justifyContent: 'space-between' }}>
                        <div>Faltante: </div> {parseFloat(filteredList[key].proc_homologado_valor - filteredList[key].proc_recebimento_valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})} 
                        </G.DistacInfoText>

                        <G.OptionsArea style={{ justifyContent: 'center', marginTop: '10px', marginBottom: '-7px' }}>                        
                        <G.Button 
                            style={{ backgroundColor: '#2E8B57', marginTop: '16px' }} 
                            onClick={() => (setStep(2))}
                          >
                            Adicionar Valor
                          </G.Button>
                          </G.OptionsArea>          
                      </G.BoxBody>
                    }

                    {step === 2 && 
                      <G.BoxBody style={{ flexWrap: 'wrap', margin: '0px 0px',  justifyContent: 'space-between', padding: '0px' }}>
                        <G.FormInputArea style={{ width: '24%' }}>
                          <G.FormInputLabel style={{ borderLeft: item.proc_tipo_proc_id === 1 ? '3px solid #E95839' : item.proc_tipo_proc_id === 2 ? '3px solid #2E8B57' : item.proc_tipo_proc_id === 3 ? '3px solid #1E90FF' : '3px solid #333' }}>
                            Código
                          </G.FormInputLabel>
                          <G.FormInput 
                            type='text' 
                            name='proc_id' 
                            disabled={true} 
                            defaultValue={filteredList[key].proc_id.toString().padStart(6)}
                            style={{ fontWeight: 'bold' }}
                          />                      
                          </G.FormInputArea>

                        {/* *** ESSE CAMPO SERÁ MANTIDO? *** */}
                          <G.FormInputArea style={{ width: '40%', marginLeft: '0px' }}>
                            <G.FormInputLabel 
                              style={{ 
                                borderLeft: 
                                  item.proc_homologado_valor === 0 ? '3px solid #1E90FF' 
                                  : item.honor_vista + item.honor_praso === 0 ? '3px solid #E95839' 
                                  : item.proc_homologado_valor > item.honor_vista + item.honor_praso ? '3px solid #FFB400' 
                                  : '3px solid #2E8B57' 
                              }}
                            >
                              Valor
                            </G.FormInputLabel>
                            <G.FormInput 
                              type='text' 
                              name='Valor'                       
                              defaultValue={'R$ 0,00'} 
                              onChange={(e) => setPayValue(Number(e.target.value.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,"")))}
                            />                      
                          </G.FormInputArea>

                          <G.FormInputArea style={{ width: '32%', marginLeft: '0px' }}>
                            <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                              Data
                            </G.FormInputLabel>
                            <G.FormInput 
                              type="date" 
                              name='Entrada' 
                              //defaultValue={today} 
                              onChange={(e) => setPayDate((moment(e.target.value).format("YYYY-MM-DD")))}
                            />
                          </G.FormInputArea>

                          <G.FormInputArea style={{ width: '38%' }}>
                            <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                              Entidade
                            </G.FormInputLabel>
                            <G.FormSelect onChange={(e) => GetSelect(e.target.value)}>
                              <option disabled selected value={''}>Selecione</option>
                              {entityType.map(i => (
                                <option key={i.entd_id} value={i.entd_id}>{i.entd_descricao}</option>
                              ))}
                            </G.FormSelect>
                          </G.FormInputArea>
                          <G.FormInputArea style={{ width: '58%', marginLeft: '0px', padding: '0px' }}>
                            <G.FormSelect style={{ borderRadius: '3px' }} onChange={(e) => setEntityName(e.target.value)}>
                              <option disabled selected value={''}>Selecione</option>
                              {entity.map(i => (
                                <option 
                                  key={   entityId === '1' ? i.fcnr_id 
                                        : entityId === '2' ? i.fncdr_id 
                                        : entityId === '3' ? i.clnt_id 
                                        : entityId === '5' ? i.emprs_id 
                                        : entityId === '6' ? i.adv_id 
                                        : undefined
                                  } 
                                  value={   entityId === '1' ? i.fcnr_nome 
                                          : entityId === '2' ? i.fncdr_nome 
                                          : entityId === '3' ? i.clnt_nome 
                                          : entityId === '5' ? i.emprs_nome 
                                          : entityId === '6' ? i.adv_nome 
                                          : undefined
                                  }
                                >
                                  {   entityId === '1' ? i.fcnr_nome 
                                    : entityId === '2' ? i.fncdr_nome 
                                    : entityId === '3' ? i.clnt_nome 
                                    : entityId === '5' ? i.emprs_nome 
                                    : entityId === '6' ? i.adv_nome 
                                    : undefined
                                  }
                                </option>
                              ))}
                            </G.FormSelect>
                          </G.FormInputArea>

                          <G.FormInputArea style={{ width: '72%' }}>
                            <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                              Conta
                            </G.FormInputLabel>
                            <G.FormSelect onChange={(e) => setSelectedPayAccount(e.target.value)}>
                              <option disabled selected value={''}>Selecione</option>
                              {payAccounts.map(i => (
                                <option key={i.cnt_id} value={i.cnt_id}>{i.cnt_descricao}</option>
                              ))}
                            </G.FormSelect>
                          </G.FormInputArea>

                          <G.Button 
                            style={{ margin:'6px 16px', backgroundColor: (payValue === '0' || payDate === '' || !payAccounts) ? '#CCC' : '#2E8B57' }} 
                            onClick={() => (PayClickConfirm(key))}
                          >
                            Salvar
                          </G.Button>

                      </G.BoxBody>
                    }

                  </G.PopUpArea>

                }
              </div>

              {/* TABELA DE VISUALIZAÇÃO DOS PROCESSOS ARQUIVADOS */}
              <C.CardHeaderInfo style={{ width: '240px', paddingLeft: '8px', borderLeft: '1px solid #333', margin: '4px' }}>
                <C.CardHeaderNumber>
                  {/* Código: {item.proc_id} */}
                  {item.proc_id}
                </C.CardHeaderNumber>
                <C.CardHeaderTitle style={{ fontSize: '12px' }}>
                  {/* {item.proc_codigo}{' - '}{item.proc_vara_junta.length > 40 ? item.proc_identificacao.substring(0,40)+' ...' : item.proc_vara_junta} */}
                  {item.proc_identificacao.length > 32 ? item.proc_identificacao.substring(0,32)+' ...' : item.proc_identificacao}
                </C.CardHeaderTitle>
              </C.CardHeaderInfo>
              
              <C.CardBodyText 
                style={{ 
                  width: '290px', 
                  height: '20px', 
                  alignItems: 'center', 
                  padding: '6px 10px', 
                  borderRadius: '0px', 
                  borderLeft: '3px solid #2E8B57', 
                  fontSize: '13px', 
                  backgroundColor: 'inherit' 
                }}
              >
                {item.proc_reclamante.length > 26 ? item.proc_reclamante.substring(0,26)+' ...' : item.proc_reclamante}
              </C.CardBodyText>
              <C.CardBodyText 
                style={{ 
                  width: '290px', 
                  height: '20px', 
                  alignItems: 'center', 
                  padding: '6px 10px', 
                  borderRadius: '0px', 
                  borderLeft: '3px solid #1E90FF', 
                  fontSize: '13px', 
                  backgroundColor: 'inherit' 
                }}
              >
                {item.proc_reclamada.length > 26 ? item.proc_reclamada.substring(0,26)+' ...' : item.proc_reclamada}
              </C.CardBodyText>

              <C.CardBodyStatusText 
                style={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  width: '70px', 
                  height: '20px', 
                  padding: '6px 12px', 
                  borderRadius: '4px', 
                  margin: '4px 6px', 
                  border: '1px solid #FFB400', 
                  backgroundColor: 'inherit', 
                  fontSize: '12px' 
                }}
              >
                {new Date(item.proc_data_entrada).toLocaleDateString()}
              </C.CardBodyStatusText>

                <C.CardBodyStatusText 
                  style={{ 
                    display: 'flex', 
                    justifyContent: 'center',
                    alignItems: 'center', 
                    width: '64px', 
                    height: '24px', 
                    padding: '4px', 
                    borderRadius: '4px', 
                    margin: '0px', 
                    backgroundColor: item.proc_tipo_proc_id === 1 ? '#F15068' : item.proc_tipo_proc_id === 2 ? '#51B476' : item.proc_tipo_proc_id === 3 ? '#84b6f4' : '#333', 
                    color: '#fff', 
                    fontWeight: 'bold'
                  }}
                >
                  {item.proc_tipo_proc_id === 1 ? 'PRCIA' : item.proc_tipo_proc_id === 2 ? 'RCMTE' : item.proc_tipo_proc_id === 3 ? 'RCMDA' : '???' }
                </C.CardBodyStatusText>


              <G.Button style={{ width: '24px', height: '24px', padding: '0px', margin: '4px', borderRadius: '4px' }}>
                <OpenInNewIcon 
                  // fontSize="medium" 
                  style={{ fontSize: '28px', color: '#333', cursor: 'pointer' }}
                  onClick={() => ProcessDetails(key, item.proc_id)} 
                />
              </G.Button>
              
            </G.TableArea>
          ))}
          

        </G.Card>
      </C.Body>

      <G.Footer style={{ color: '#333', fontSize: '12px' }}>
        {/* <p> Copyright <CopyrightIcon style={{ fontSize: '12px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
      </G.Footer>


      {loadingModal &&  
        <C.ModalContainer>
          <C.Logo src={"/loading.svg"} />
        </C.ModalContainer>
      }

      {modal !== false &&
        <M.ModalContainer>
          <M.ModalContentLarge style={{ justifyContent: 'center' }}>

            <M.ModalHeader 
              style={{ height:'28px', justifyContent: 'space-evenly' }}>
              <TaskIcon 
                fontSize="large"
                style={{ 
                  color: filteredList[selectedProcess].proc_tipo_proc_id === 1 ? '#F15068' // PROCESSO – TIPO – 1 – PERÍCIA
                       : filteredList[selectedProcess].proc_tipo_proc_id === 2 ? '#51B476' // PROCESSO – TIPO – 2 – RECLAMANTE
                       : filteredList[selectedProcess].proc_tipo_proc_id === 3 ? '#84b6f4' // PROCESSO – TIPO – 3 – RECLAMADA
                       : '#333' // PROCESSO – TIPO – OUTRO
                  }}
              />
              <G.InfoText style={{ width: '96%', height: '28px', alignItems: 'flex-end',fontSize: '18px', color: '#222' }}>
                {'Detalhes do Processo'}
                {' | '}
                {filteredList[selectedProcess].proc_tipo_proc_id === 1 ? 'Perícia' : filteredList[selectedProcess].proc_tipo_proc_id === 2 ? 'Reclamante' : filteredList[selectedProcess].proc_tipo_proc_id === 3 ? 'Reclamada' : 'Outro'}
              </G.InfoText>
            </M.ModalHeader>

            <M.ModalHeader style={{ height:'0px', border: 'none' }}>

              {actsFull && // Pop-up das Atividades da Fase
                <G.PopUpArea  style={{ marginLeft: '0px', marginTop: '300px', width: '700px' }}>
                  <G.PopUpCloseArea onClick={() => setActsFull('')}>
                    <CloseIcon style={{ fontSize: '20px' }}/>
                  </G.PopUpCloseArea>
                  <G.DistacInfoText style={{ padding: '7px', borderRadius: '6px 6px 6px 6px', backgroundColor: '#e5e5e5', marginBottom: '8px' }}>
                    <TaskAltOutlinedIcon style={{ fontSize: '24px', marginRight: '5px' }}/>
                    Atividades
                  </G.DistacInfoText>
                  {atividades.map((item, index) => (
                    <G.TableArea key={index} style={{ justifyContent: 'space-around' }}>
                    <G.FieldArea size={'30%'}>
                      <G.InfoText>{item.ctrl_atvd_nome}</G.InfoText>
                    </G.FieldArea>
                    <G.FieldArea size={'24%'} style={{ backgroundColor: '#ebebeb' }}>
                      <G.InfoText style={{ padding: '0px 4px' }}>{item.usr_nome}</G.InfoText>
                    </G.FieldArea>
                    <G.FieldArea size={'10%'} style={{ color: '#fff', backgroundColor: '#2E8B57', justifyContent:'center' }}>
                      {item.ctrl_atvd_inicio === null 
                        ? <G.InfoText>--/--/----</G.InfoText> 
                        : <G.InfoText>{new Date(item.ctrl_atvd_inicio).toLocaleDateString()}</G.InfoText>
                      }
                      {/* <G.InfoText>{new Date(item.ctrl_atvd_inicio).toLocaleDateString('pt-BR')}</G.InfoText> */}
                    </G.FieldArea>
                    <G.FieldArea size={'10%'} style={{ color: '#fff',backgroundColor: '#E95839', justifyContent:'center' }}>
                      {item.ctrl_atvd_fim === null 
                        ? <G.InfoText>--/--/----</G.InfoText> 
                        : <G.InfoText>{new Date(item.ctrl_atvd_fim).toLocaleDateString()}</G.InfoText>
                      }
                      {/* <G.InfoText>{new Date(item.fim).toLocaleDateString('pt-BR')}</G.InfoText> */}
                    </G.FieldArea>
                    <G.FieldArea size={'10%'} style={{ color: '#fff',backgroundColor: '#555', justifyContent:'center' }}>
                      <G.InfoText>
                        {
                          parseInt(moment.duration(item.ctrl_atvd_duracao).asHours()).toString().padStart(2, '0') + 'h' + 
                          parseInt(moment.duration(item.ctrl_atvd_duracao).asMinutes() % 60).toString().padStart(2, '0') + 'm'
                        }
                      </G.InfoText>
                    </G.FieldArea>
                      </G.TableArea>
                    ))
                  }
    
                  <G.InfoArea style={{ width: '170px', height: '28px', padding: '6px', marginTop: '8px', backgroundColor: '#f5f5f5' }}>
                    <G.InfoText style={{ marginRight: '3px' }}>Total da fase:  </G.InfoText>
                    <G.DistacInfoText style={{marginRight: '10px'}}>
                      {
                        parseInt(moment.duration(atividades.reduce((a, b) => a + parseFloat(b.ctrl_atvd_duracao), 0)).asHours()).toString().padStart(2, '0') + 'h' +
                        parseInt(moment.duration(atividades.reduce((a, b) => a + parseFloat(b.ctrl_atvd_duracao), 0)).asMinutes() % 60).toString().padStart(2, '0') + 'm'
                      } 
                    </G.DistacInfoText> 
                  </G.InfoArea>
                </G.PopUpArea>
              }

              {addProcessPhase && // Adicinar nova Fase a um Processo Arquivado
                <G.PopUpArea  style={{ width: '700px', height: '432px', alignItems: 'center', marginLeft: '0px', marginTop: '400px' }}>

                  <G.PopUpCloseArea onClick={() => setAddProcessPhase('') + setActivityPhase('') + setFaseEntrada('') + setFasePrazo('')}>
                    <CloseIcon style={{ fontSize: '20px' }}/>
                  </G.PopUpCloseArea>

                  <G.SmallTitle style={{ width: '100%', height: '88px', padding: '10px', borderRadius: '4px', justifyContent: 'space-between', backgroundColor: '#f5f5f5' }}>
                    <div>
                      {'Adicionar Fase'}
                    </div>
                    <div style={{ fontWeight: 'normal', fontSize: '14px' }}>
                      {'Processo: '}
                      {filteredList[selectedProcess].proc_id} 
                      {' | '}
                      {filteredList[selectedProcess].proc_tipo_proc_id === 1 ? 'Perícia' : filteredList[selectedProcess].proc_tipo_proc_id === 2 ? 'Reclamante' : filteredList[selectedProcess].proc_tipo_proc_id === 3 ? 'Reclamada' : '???'}
                    </div>
                  </G.SmallTitle>
                  <G.BoxBody style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'start', color: '#fff' }}>

                    <G.FormInputArea style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                      <div style={{ display: 'flex', flexDirection: 'row', width: '98%', justifyContent: 'space-between', margin: '4px 2px 8px 8px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '40%' }}>
                          <G.FormInputLabel style={{ borderLeft: '3px solid green', borderRadius: '4px 0px 0px 4px' }}>
                            Entrada
                          </G.FormInputLabel>
                          <G.FormInput type="date" onChange={(e) => setFaseEntrada(e.target.value)}/> 
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '40%' }}>
                          <G.FormInputLabel style={{ borderLeft: '3px solid orange', borderRadius: '4px 0px 0px 4px' }}>
                            Prazo
                          </G.FormInputLabel>
                          <G.FormInput type="date" min={faseEntrada} onChange={(e) => setFasePrazo(e.target.value)}/>
                        </div>
                      </div>
                      <div style={{ width: '96%', justifyContent: 'space-around', margin: '4px' }}>
                        <G.FormSelect style={{ borderRadius: '4px' }} onChange={e => GetActivityPhase(e.target.value)} onMouseOver={() => GetSelect(7)}>
                          <option>Selecione a Fase a adicionar</option>
                          {phase.map(p => (
                            <option key={p.fase_id} value={p.fase_id}>{p.fase_descricao}</option>
                          ))}
                        </G.FormSelect>
                      </div>

                    </G.FormInputArea>

                  </G.BoxBody>

                  <G.InfoArea style={{ width: '520px', padding: '10px', marginBottom: '16px', borderRadius: '0px', borderBottom: '1px solid #ccc', fontSize: '14px', color: '#000' }}>
                    Atividades
                  </G.InfoArea>
                  {(activityPhase === '' || activityPhase.length === 0) &&
                    <G.InfoArea style={{ justifyContent: 'space-around', padding: '6px', borderRadius: '4px', backgroundColor: '#FFEEAD' }}>
                      <WarningRoundedIcon  style={{ color: '#ffb400'  }}/>
                      <G.InfoText>
                        Fase com nenhuma Atividade cadastrada.
                      </G.InfoText>
                    </G.InfoArea>
                  }
                    <>
                      <div style={{ display: 'flex', width: '100%', height: '300px', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '16px' }}> 
                        {activityPhase !== '' &&
                          <G.InfoArea style={{ width: '520px', flexDirection: 'row', justifyContent: 'space-aroud', flexWrap: 'wrap' }}> 
                          {activityPhase.map((item, key)=>(
                            <G.FormInputArea key={key} style={{ flexDirection: 'column', alignItems: 'start', marginRight: '20px' }}>
                              <G.FormInputLabel style={{ 
                                  width: '100%', 
                                  justifyContent: 'center', 
                                  borderRadius: '4px', 
                                  borderLeft: '0px solid black', 
                                  backgroundColor: '#777',
                                  color: '#FFF', 
                                  // fontWeight: 'normal''
                                }}
                              >
                                {item.ctrl_atvd_nome}
                              </G.FormInputLabel>
                            </G.FormInputArea>
                          ))}  
                          </G.InfoArea>
                        }
                      </div>
                    </>
                  <G.InfoArea>
                    <G.Button 
                      style={{ backgroundColor: (activityPhase.length === 0 || faseEntrada === '' || fasePrazo === '') ? '#BBB' : '#2E8B57' }} 
                      onClick={() => (activityPhase.length === 0 || faseEntrada === '' || fasePrazo === '') ? undefined : AddProcessPhase()}
                    >
                      Adicionar
                    </G.Button>
                  </G.InfoArea>
                </G.PopUpArea>
              }

            </M.ModalHeader>
            
            {/* DETALHES DO PROCESSO ARQUIVADO */}
            <M.ModalBody style={{ width: '1000px', flexDirection: 'column', alignItems: 'center', marginTop: '5px' }}>
              <G.BoxBody style={{ flexWrap: 'wrap',  justifyContent: 'start', margin: '0px 0px' }}>

                <G.FormInputArea style={{ width: '15%' }}>
                  <G.FormInputLabel style={{ borderLeft: '3px solid gray' }}>
                    ID
                  </G.FormInputLabel>
                  <G.FormInput 
                    type='text' 
                    name='id_proc' 
                    readOnly={true}
                    defaultValue={filteredList[selectedProcess].proc_id.toString().padStart(6)} 
                    style={{ fontSize: '13px', color: '#111' }}
                    {...register("proc_id")}
                  />                      
                </G.FormInputArea>

                <G.FormInputArea style={{ width: '31.8%' }}> 
                  <G.FormInputLabel style={{ borderLeft: '3px solid purple'}}>
                    Identificação
                  </G.FormInputLabel>
                  <G.FormInput 
                    type='text' 
                    name='identificacao' 
                    defaultValue={
                      filteredList[selectedProcess].proc_identificacao === null 
                      ? '' 
                      : filteredList[selectedProcess].proc_identificacao
                    } 
                    {...register("proc_identificacao", { required: true })}
                  />
                </G.FormInputArea>

                <G.FormInputArea style={{ width: '26.5%'}}>
                  <G.FormInputLabel style={{ borderLeft: '3px solid orange' }}>
                    Código
                  </G.FormInputLabel>
                  <G.FormInput 
                    type='text' 
                    name='codigo' 
                    defaultValue={
                      filteredList[selectedProcess].proc_codigo === null 
                      ? '' 
                      : filteredList[selectedProcess].proc_codigo
                    } 
                    {...register("proc_codigo", { required: true })}
                  />  
                </G.FormInputArea>
                
                <G.FormInputArea  style={{ width: '20%' }}>
                  <G.FormInputLabel>
                    Entrada
                  </G.FormInputLabel>
                  <G.FormInput 
                    type="date" 
                    name='data_entrada'
                    defaultValue={moment(filteredList[selectedProcess].proc_data_entrada).format("YYYY-MM-DD")} 
                    {...register("proc_data_entrada", { required: true }, { setValueAs: v => moment(v).format("YYYY-MM-DD") })}
                  /> 
                </G.FormInputArea>

                <G.FormInputArea style={{ width: '48%' }}>
                  <G.FormInputLabel>
                    Junta
                  </G.FormInputLabel>
                  <G.FormInput 
                    type='text' 
                    name='vara_junta'
                    defaultValue={
                      filteredList[selectedProcess].proc_vara_junta === null 
                      ? '' 
                      : filteredList[selectedProcess].proc_vara_junta
                    } 
                    {...register("proc_vara_junta", { required: true })}
                  /> 
                </G.FormInputArea>

                {/* <G.FormInputArea style={{ width: '20%' }}>
                  <G.FormInputLabel>Prazo: </G.FormInputLabel><G.FormInput defaultValue={moment(filteredList[selectedProcess].prazo).format("YYYY-MM-DD")} type="date"
                  {...register("prazo", {setValueAs: v => moment(v).format("YYYY-MM-DD")})}/>                      
                </G.FormInputArea> */}

                <G.FormInputArea style={{ width: '48%' }}>
                  <G.FormInputLabel style={{ borderLeft: '3px solid navy' }}>
                    Número
                  </G.FormInputLabel>
                  <G.FormInput 
                    type='text'
                    name='processNumber'
                    placeholder='Código Processo TRT'
                    value={
                      processNumber === null 
                      ? '' 
                      : mask(processNumber, ['9999999-99.9999.9.99.9999'])
                    }
                    onChange={onChange}
                  />
                </G.FormInputArea>

                {/* <G.FormInputArea style={{ width: '20%' }}></G.FormInputArea> */}

                <G.FormInputArea style={{ width: '48%' }}>
                  <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                    Reclamante
                  </G.FormInputLabel>
                  <G.FormInput 
                    type='text' 
                    name='reclamante' 
                    defaultValue={
                      filteredList[selectedProcess].proc_reclamante === null 
                      ? '' 
                      : filteredList[selectedProcess].proc_reclamante
                    } 
                    {...register("proc_reclamante", { required: true })}
                  />                      
                </G.FormInputArea>

                {/* PROCESSO RECLAMANTE || PROCESSO RECLAMADA */}
                {(filteredList[selectedProcess].proc_tipo_proc_id === 2 || filteredList[selectedProcess].proc_tipo_proc_id === 3 ) &&
                  <G.FormInputArea style={{ width: '48%' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid gray' }}>
                      Advogado
                    </G.FormInputLabel>
                    <G.FormSelect 
                      type='text' 
                      name='advogado' 
                      defaultValue={
                        filteredList[selectedProcess].proc_advogado_id === null 
                        ? '' 
                        : filteredList[selectedProcess].proc_advogado_id
                      } 
                      {...register("proc_advogado_id", 
                        { required: (filteredList[selectedProcess].proc_tipo_proc_id === 2 || filteredList[selectedProcess].proc_tipo_proc_id === 3 ) ? true : false }, 
                        { setValueAs: v => (filteredList[selectedProcess].proc_tipo_proc_id === 2 || filteredList[selectedProcess].proc_tipo_proc_id === 3 ) ? v : 'N/A' }
                      )}
                    >
                      <option>{filteredList[selectedProcess].adv_nome}</option>
                        {lawyer.map(a => (
                          <option key={a.adv_id} value={a.adv_id}>{a.adv_nome}</option>
                        ))}
                    </G.FormSelect>                      
                  </G.FormInputArea>
                }

                {/* PROCESSO PERÍCIA */}
                {filteredList[selectedProcess].proc_tipo_proc_id === 1 && 
                  <>
                    <G.FormInputArea style={{ width: '48%' }}>
                      <G.FormInputLabel style={{ borderLeft: '3px solid #E95839' }}>
                        Perito
                      </G.FormInputLabel>
                      <G.FormInput 
                        type='text' 
                        name='perito'
                        defaultValue={
                          filteredList[selectedProcess].proc_perito_nome === null 
                          ? '' 
                          : filteredList[selectedProcess].proc_perito_nome
                        } 
                        {...register("proc_perito_nome", 
                          { required: filteredList[selectedProcess].proc_tipo_proc_id === 1 ? true : false }, 
                          { setValueAs: v => filteredList[selectedProcess].proc_tipo_proc_id === 1 ? v : 'N/A' }
                        )}
                      />                      
                    </G.FormInputArea>
                  </>
                }

                <G.FormInputArea style={{ width: '48%' }}>
                  <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                    Reclamada
                  </G.FormInputLabel>
                  <G.FormInput 
                    type='text' 
                    name='reclamada' 
                    defaultValue={
                      filteredList[selectedProcess].proc_reclamada === null 
                      ? '' 
                      : filteredList[selectedProcess].proc_reclamada
                    } 
                    {...register("proc_reclamada", { required: true })}
                  />                      
                </G.FormInputArea>

                <G.FormInputArea style={{ width: '48%' }}>
                  <G.FormInputLabel>
                    Calculista
                  </G.FormInputLabel>
                     <G.FormSelect 
                      type='text' 
                      name='calculista' 
                      defaultValue={
                        filteredList[selectedProcess].proc_calculista_nome === null 
                        ? '' 
                        : filteredList[selectedProcess].proc_calculista_nome
                      } 
                      {...register("proc_calculista_nome", { required: true })}
                    >
                     <option >{filteredList[selectedProcess].usr_id}</option>
                        {users.map(u => (
                          <option key={u.usr_id} value={u.usr_nome}>{u.usr_nome}</option>
                        ))}
                      </G.FormSelect>                     
                </G.FormInputArea>

                {/* PROCESSO RECLAMADA */}
                {filteredList[selectedProcess].proc_tipo_proc_id === 3 &&
                  <G.FormInputArea style={{ width: '48%' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid gray' }}>
                      Empresa
                    </G.FormInputLabel>
                    <G.FormSelect 
                      type='text' 
                      name='empresa'                     
                      defaultValue={
                        filteredList[selectedProcess].proc_empresa_id === null 
                        ? '' 
                        : filteredList[selectedProcess].proc_empresa_id
                      } 
                      {...register("proc_empresa_id", 
                        { required: filteredList[selectedProcess].proc_tipo_proc_id === 3 ? true : false }, 
                        { setValueAs: v => filteredList[selectedProcess].proc_tipo_proc_id === 3 ? v : 'N/A' }
                      )}
                    >
                      <option >{filteredList[selectedProcess].emprs_nome}</option>
                      {company.map(a => (
                        <option key={a.emprs_id} value={a.emprs_id}>{a.emprs_nome}</option>
                      ))}
                    </G.FormSelect> 
                  </G.FormInputArea>
                }

                {/* PROCESSO PERÍCIA */}
                {filteredList[selectedProcess].proc_tipo_proc_id === 1 &&
                  <>
                    <G.FormInputArea style={{ width: '23%' }}>
                      <G.FormInputLabel>
                        Honorário
                      </G.FormInputLabel>
                      <G.FormInput 
                        type='text' 
                        name='honorario'                       
                        defaultValue={
                          filteredList[selectedProcess].proc_honorario_valor === null 
                          ? '0' 
                          : parseFloat(filteredList[selectedProcess].proc_honorario_valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
                        } 
                        {...register("proc_honorario_valor", 
                          // { required: filteredList[selectedProcess].proc_tipo_proc_id === 1 ? true : false }, 
                          // {setValueAs: v => filteredList[selectedProcess].proc_tipo_proc_id === 1 ? Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,"")) : 0}
                          {setValueAs: v => Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))}
                        )}
                      />                  
                    </G.FormInputArea>

                    <G.FormInputArea style={{ width: '23%' }}>
                      <G.FormInputLabel>
                        Homologado
                      </G.FormInputLabel>
                      <G.FormInput 
                        type='text' 
                        name='homologado'                       
                        defaultValue={
                          filteredList[selectedProcess].proc_homologado_valor === null 
                          ? '0' 
                          : parseFloat(filteredList[selectedProcess].proc_homologado_valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
                        } 
                        {...register("proc_homologado_valor", 
                          // { required: filteredList[selectedProcess].proc_tipo_proc_id === 1 ? true : false }, 
                          // {setValueAs: v => filteredList[selectedProcess].proc_tipo_proc_id === 1 ? Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,"")) : 0}
                          {setValueAs: v => Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))}
                        )}
                      />                      
                    </G.FormInputArea>
                  </>
                }
              </G.BoxBody>
                  
              <div>
                {
                  (Object.keys(errors).length !== 0 || stateErrors) && 
                  <div role="alert" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', borderRadius: '4px', fontSize: '12px', backgroundColor: '#FCAC9B', color: '#000' }}>
                    <WarningRoundedIcon  style={{ color: '#333', marginRight: '5px'  }}/>
                    {'PARA SALVAR OU ADICIONAR UMA NOVA FASE TODOS OS CAMPOS DEVEM ESTAR PREENCHIDOS.'}
                  </div>
                }
              </div>

              {/* DETALHES PROCESSO – FASES DO PROCERSSO ARQUIVADO – CABEÇALHO */}
              <G.Box style={{ flexDirection: 'column', width: filteredList[selectedProcess].proc_tipo_proc_id === 3 ? '95%' : '70%', height: '180px', justifyContent: 'start', backgroundColor: '#ddd' }}>
                <G.TableHeader style={{ width: '100%', borderBottom: '2px solid #bbb', backgroundColor: '#ddd'}}>

                  <G.OptionsArea style={{ width: '100%', justifyContent: 'space-around', padding: '4px 1px' }}>
                    <G.FieldArea size={'160px'} style={{ marginRight: '4px', marginLeft: '1px', borderLeft: '2px solid inherit' }}>
                      <G.DistacInfoText>Fase</G.DistacInfoText>
                    </G.FieldArea>
                    <G.FieldArea size={'76px'} style={{ marginRight: '4px' }}>
                      <G.DistacInfoText>Entrada</G.DistacInfoText>
                    </G.FieldArea>
                    <G.FieldArea size={'76px'} style={{ marginRight: '4px' }}>
                      <G.DistacInfoText>Prazo</G.DistacInfoText>
                    </G.FieldArea>
                    <G.FieldArea size={'76px'} style={{ marginRight: '4px' }}>
                      <G.DistacInfoText>Saída</G.DistacInfoText>
                    </G.FieldArea>
                    <G.FieldArea size={'152px'} style={{ marginRight: '8px' }}>
                      <G.DistacInfoText>Etapa</G.DistacInfoText>
                    </G.FieldArea>

                    {/* PROCESSO RECLAMADA */}
                    {filteredList[selectedProcess].proc_tipo_proc_id === 3 &&
                      <>
                        <G.FieldArea size={'104px'} style={{ marginRight: '4px' }}><G.DistacInfoText>Valor</G.DistacInfoText></G.FieldArea>
                        <G.FieldArea size={'68px'} style={{ marginRight: '4px' }}><G.DistacInfoText>Cobrança</G.DistacInfoText></G.FieldArea>                        
                      </>
                    }

                    <G.FieldArea size={'86px'}>
                      <G.DistacInfoText>Ações</G.DistacInfoText>
                    </G.FieldArea>
                  </G.OptionsArea>
                </G.TableHeader>

                {/* DETALHES PROCESSO – FASES DO PROCERSSO ARQUIVADO – TABELA */}
                <G.TableBodyScroll>
                  {fasesProcessoList.map((item, key) => (
                    <G.TableArea key={key} style={{ width: '100%', justifyContent: 'space-around', padding: '4px 1px', borderRadius: '4px' }}>

                      <G.FieldArea 
                        size={'160px'} 
                        style={{ 
                          borderRadius: '0px', 
                          marginLeft: '2px',
                          borderLeft: item.fase_proc_etapa_id === 1 ? '2px solid green'  // 1 – Etapa – Aberto
                                    : item.fase_proc_etapa_id === 2 ? '2px solid orange' // 2 – Etapa – Faturamento
                                    : item.fase_proc_etapa_id === 3 ? '2px solid blue'   // 3 – Etapa – Faturado
                                    : item.fase_proc_etapa_id === 4 ? '2px solid orange' // 4 – Etapa – Pgmto Aberto
                                    : item.fase_proc_etapa_id === 5 ? '2px solid gray'   // 5 – Etapa – Quitado
                                    : item.fase_proc_etapa_id === 6 ? '2px solid grey'   // 6 – Etapa – Encerrado
                                    : '2px solid red'
                        }}
                      >
                        <G.InfoText style={{ marginLeft: '0px' }}>{item.fase_descricao}</G.InfoText>
                      </G.FieldArea>

                      <G.FieldArea size={'76px'}>
                        <G.InfoText>{new Date(item.fase_proc_data_entrada).toLocaleDateString()}</G.InfoText>
                      </G.FieldArea>

                      <G.FieldArea size={'76px'}>
                        {item.fase_proc_data_pazo === null 
                          ? <G.InfoText>--/--/----</G.InfoText> 
                          : <G.InfoText>{new Date(item.fase_proc_data_pazo).toLocaleDateString()}</G.InfoText>
                        }
                      </G.FieldArea>

                      <G.FieldArea size={'76px'}>
                        {item.fase_proc_data_saida === null 
                          ? <G.InfoText>--/--/----</G.InfoText> 
                          : <G.InfoText>{new Date(item.fase_proc_data_saida).toLocaleDateString()}</G.InfoText>
                        }
                      </G.FieldArea>

                      <G.FieldArea 
                        size={'152px'} 
                        style={{ 
                          minHeight: '38px', 
                          justifyContent: 'flex-start', 
                          marginRight: '4px', 
                          borderRadius: '0px', 
                          borderBottom: item.fase_proc_etapa_id === 1 ? '2px solid green'  // 1 – Etapa – Aberto
                                      : item.fase_proc_etapa_id === 2 ? '2px solid orange' // 2 – Etapa – Faturamento
                                      : item.fase_proc_etapa_id === 3 ? '2px solid blue'   // 3 – Etapa – Faturado
                                      : item.fase_proc_etapa_id === 4 ? '2px solid orange' // 4 – Etapa – Pgmto Aberto
                                      : item.fase_proc_etapa_id === 5 ? '2px solid gray'   // 5 – Etapa – Quitado
                                      : item.fase_proc_etapa_id === 6 ? '2px solid grey'   // 6 – Etapa – Encerrado
                                      : '2px solid red'
                        }}
                      >
                        <G.InfoText>{item.etp_descricao}</G.InfoText>
                      </G.FieldArea>

                      {/* PROCESSO RECLAMADA */}
                      {filteredList[selectedProcess].proc_tipo_proc_id === 3 &&
                        <>
                          <G.FieldArea size={'104px'} style={{ justifyContent: 'flex-start' }}>
                            <G.InfoText>{parseFloat(item.fase_proc_valor_recebido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})}</G.InfoText>
                          </G.FieldArea>
                          <G.FieldArea size={'68px'} style={{ display: 'flex',justifyContent: 'center', alignItems: 'center' }}>
                            <G.InfoText>{item.fase_proc_cobranca === 1 ? <DoneRoundedIcon style={{ color: 'green' }} /> : <ClearRoundedIcon style={{ color: 'grey' }} />}</G.InfoText>
                          </G.FieldArea>
                        </>
                      }
                      
                      {/* BOTÕES DE AÇÃO */}
                      <G.FieldArea size={'92px'} style={{ justifyContent: 'flex-end', minHeight: '38px', padding: '1px' }}>
                        <G.InfoText style={{ padding: '2px' }}> 
                          
                          {/* <G.IconButton fontSize="small" style={{ backgroundColor: '#E95839' }}>
                            <DeleteIcon/>
                          </G.IconButton> */}
                          
                          <G.IconButton style={{ backgroundColor: '#FFB400' }} onClick={() => GetAtividades(item.fase_proc_id) }>
                            <AutoAwesomeMotionIcon fontSize="small"/>
                          </G.IconButton>

                        </G.InfoText>
                      </G.FieldArea>
                    </G.TableArea> 
                  ))}

                </G.TableBodyScroll>
              </G.Box>
            </M.ModalBody>

            <M.ModalFooter style={{ flexDirection: 'row', height: '24px', padding: '16px 8px 8px 8px' }}>

              {fasesProcessoList.filter(qitem => qitem.fase_proc_etapa_id === 1).length === 0 &&
                <G.Button style={{ backgroundColor:  '#1E90FF' }} onClick={() => handleSubmit(onSubmit)(1)}>
                  Adicionar Fase
                </G.Button>
              }
              <G.Button style={{ backgroundColor: '#E95839' }} onClick={() => CloseModalClick('0')}>
                Fechar
              </G.Button>

              <G.Button style={{ backgroundColor: '#2E8B57' }} onClick={() => handleSubmit(onSubmit)(2)}>
                Salvar
              </G.Button>
            </M.ModalFooter>

          </M.ModalContentLarge>
        </M.ModalContainer>
      }

    </C.Container>
  );
}
