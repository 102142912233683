import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { mask, unMask } from 'remask';

import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'

import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';
import TaskIcon from '@mui/icons-material/Task';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';


/// DATATABLE ICONS ////////////////////////////////////////////////////////////

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



export const Users = () => {
  
  const navigate = useNavigate();
  const {register, handleSubmit, formState: { errors }, reset} = useForm({ mode: 'resolver' });

  const { state: user } = useContext(UserContext)

  const [state, setState] = useState('')
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [alertError, setAlertError] = useState('')
  const [selectedKey, setSelectedKey] = useState('')
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false)

  const [admField, setAdmField] = useState(false)
  const [calField, setCalField] = useState(false)
  const [auxField, setAuxField] = useState(false)

  const [empty, setEmpty] = useState(null)
  const [stateErrors, setStateErros] = useState(false);



/// MASK ///////////////////////////////////////////////////////////////////////

  const [phoneNumber, setPhoneNumber] = useState('')

  const onChangePhone = ev => {
    
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['(99) 9999-9999', '(99) 9 9999-9999']);

    setPhoneNumber(unMask(maskedValue));

  }


////////////////////////////////////////////////////////////////////////////////




/// DATATABLE STATES /////////////////////////////////////////////////////////

  const [filteredList, setFilteredList] = useState([])
  const [order, setOrder] = useState('')
  const [activeOrder, setActiveOrder] = useState('')
  

  
  /// DATATABLE FUNCTIONS ////////////////////////////////////////////////////////

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase()
    let newArray = list.filter((data) => {
        return data.usr_nome.toLowerCase().search(value) !== -1 
            || data.usr_login.toLowerCase().search(value) !== -1 
            || data.usr_email.toLowerCase().search(value) !== -1 
            || data.usr_telefone.toLowerCase().search(value) !== -1 
    })
    setFilteredList(newArray);
  }

  const OrderClick = (field) => {
    setActiveOrder(field)
    let result = filteredList.sort(function(a, b){
      if (order === 'asc'){
        return a[field] < b[field] ? -1 : true
      } else {
        return a[field] > b[field] ? -1 : true
      }
    })
    order === 'asc' ? setOrder('desc') : setOrder('asc')
    setFilteredList(result)
  }
    


/// FUNCTIONS ////////////////////////////////////////////////////////////////

  const AddClick = () => {
    setModalType(1);
    setModal(true);
  }  

  const EditClick = (key) => {
    setSelectedKey([key]);
    setPhoneNumber(filteredList[key].usr_telefone);
    setAdmField(list[key].usr_adm === 1 ? true : false);
    setCalField(list[key].usr_cal === 1 ? true : false);
    setAuxField(list[key].usr_aux === 1 ? true : false);
    setModalType(2);
    setModal(true);
  }

  const DelClick = (key) => {
    setSelectedKey([key]);
    setModalType(3);
    setModal(true);
  }

  const ActClick = (key) => {
    setSelectedKey([key]);
    setModalType(4);
    setModal(true);
  }


  const CloseModalClick = () => {
    setLoading(true);
    setSelectedKey('');
    setPhoneNumber('');
    setStateErros(false)
    setAlertError('');
    setAdmField(false);
    setCalField(false);
    setAuxField(false);
    GetUser();
    setModal(false);
    setModalType('');
    reset();
    setLoading(false);
  }

    const CloseModalClick2 = () => {
    setLoading(true);
    setSelectedKey('');
    setPhoneNumber('');
    setStateErros(false)
    setAlertError('');
    setAdmField(false);
    setCalField(false);
    setAuxField(false);
    setModal(false);
    setModalType('');
    reset();
    setLoading(false);
  }


  const GetUser = async () => {
    // let userToken = localStorage.getItem('SessionID')
    setLoading(true);
    let res = await Api.getUser();
    // console.log("GetUser > res : ", res)
    setList(res);
    setFilteredList(res);
    setLoading(false);
  }


  const onSubmit = async (data) => {
    data["usr_telefone"] = phoneNumber
    const perms = ({
      usr_adm: admField === false ? 0 : 1, 
      usr_cal: calField === false ? 0 : 1, 
      usr_aux: auxField === false ? 0 : 1
    })
    const result = ({...data, ...perms})
    // console.log('onSubmit result: ', result)

    if (phoneNumber === null || phoneNumber === '') {
      setStateErros(true)
    } else {
      setStateErros(false)
    
      switch (modalType) {
        case 1:
          const userAdd = ({
            usr_usuario_criacao: user.id,
            usr_usuario_atualizacao: user.id
          })
          const add = ({...result, ...userAdd})
          let res1 = await Api.postUser(add)
          // console.log("addUser: ", res1)
        break;

        case 2:
          const userUpd = ({
            usr_usuario_atualizacao: user.id
          })
          const upd = ({...result, ...userUpd})
          let res2 = await Api.postUser(upd)
          // console.log("updUser: ", res2)
        break;

        default:
        break;
      }

      CloseModalClick()
      GetUser()

    }
  }


  const DelUser = async (id) => {
    let res = await Api.delUser(id);
    GetUser();
  }


  const ActUser = async (id, estado) => {
    const data = ({
      usr_id: id, 
      usr_estado: estado, 
      usr_usuario_atualizacao: user.id
    })
    let res = await Api.postUser(data)
    // console.log("ActUser > res : ", res)
    GetUser()
  }







/// DETECTA A TECLA ESCAPE (ESC) ///////////////////////////////////////////////

  document.onkeydown = function (e) {
    if (e.key === 'Escape') {
      CloseModalClick();
    }
  }



/// USE EFECT //////////////////////////////////////////////////////////////////

  useEffect(()=>{
    GetUser();
  }, [state]);


/// CONSOLE LOG ////////////////////////////////////////////////////////////////

  //console.log('GetUser > filteredList:', filteredList);



  return (
    <G.Container>

      {/* <G.Header>
        <G.HeaderTitleArea>
          <AppRegistrationOutlinedIcon fontSize='large' style={{ marginRight: "7px" }} />
          <G.MainTitle>| Cadastro</G.MainTitle>
        </G.HeaderTitleArea>
      </G.Header> */}

      <G.Header>

        <G.HeaderTitleArea style={{ width: '240px' }}>
          <AccountCircleIcon style={{ fontSize: '32px',margin: '0px 10px 0px 0px' }}/>
          <G.MainTitle>Usuários</G.MainTitle>
        </G.HeaderTitleArea>

        <G.HeaderTitleArea style={{ width: '320px' }}>
        </G.HeaderTitleArea>

        <G.HeaderTitleArea style={{ width: '280px', justifyContent: 'center' }}>
          <G.SearchArea style={{ height: '40px', color: '#444', justifyContent: 'space-between' }}>
            <G.SearchIconArea>
              <SearchIcon />
            </G.SearchIconArea>
            <G.SearchInput id='search' onChange={(event) => handleSearch(event)} />
          </G.SearchArea>        
        </G.HeaderTitleArea>

        {/* SOMENTE O USUÁRIO JOY, ID === 1 (NA VERSÃO DEMO), PODE ADICIONAR USUÁRIOS */}
        <G.HeaderTitleArea style={{ width: '120px', justifyContent: 'end' }}>
          {/* <G.Button style={{ backgroundColor: user.id === 1 ? '#2E8B57' : '#BBB', justifyContent: 'space-around' }} onClick={user.id === 1 ? AddClick : undefined}> // Para Versão Demo */}
          <G.Button style={{ backgroundColor: '#2E8B57', justifyContent: 'space-around' }} onClick={AddClick}>
            <AddIcon fontSize='small'/>
            {'Adicionar'}
          </G.Button>
        </G.HeaderTitleArea>

      </G.Header>

      <G.Body>

        {loading &&  
          <G.Logo src={"/loading.svg"} />
        }

        {(!loading && filteredList.length === 0) &&
          <G.OptionsArea style={{justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column'}}>
            <ErrorOutlineRoundedIcon style={{fontSize: '80px', color: '#aaa' }}/>
            <G.MainTitle style={{fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
              Não existem itens para a seleção atual! 
            </G.MainTitle>
          </G.OptionsArea>
        }

        {(!loading && filteredList.length > 0)  && 
          <>
            <G.Box>

              <G.TableHeader>
                <G.OptionsArea style={{ padding: '0px' }}>
                  <G.FieldArea size={'48px'} style={{ padding: '0px', justifyContent: 'center' }}><G.DistacInfoText></G.DistacInfoText></G.FieldArea>
                  <G.FieldArea size={'186px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('usr_nome')}><G.DistacInfoText>Nome</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'usr_nome') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'120px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('usr_login')}><G.DistacInfoText>Usuário</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'usr_login') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'126px'} style={{ cursor: 'pointer', padding: '0px 5px', margin: '0px 5px' }} onClick={() => OrderClick('funcao_usuario')}><G.DistacInfoText>Função</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'funcao_usuario') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'190px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('usr_email')}><G.DistacInfoText>E-mail</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'usr_email') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'120px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('usr_telefone')}><G.DistacInfoText>Telefone</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'usr_telefone') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}><G.DistacInfoText>Ações</G.DistacInfoText></G.FieldArea>
                </G.OptionsArea>
              </G.TableHeader>

              {(loading && filteredList.length === 0 ) &&  
                <G.Loading2 src={"/loading.svg"} />
              }

              <G.TableBody style={{ justifyContent: 'start', marginTop: '0px'}}>
                {(loading === false && filteredList.length === 0) && 
                  <G.Text style={{ height: '40px', justifyContent: 'center', alignItems: 'center', marginTop: '20px', fontSize: '13px' }}>
                    *** Não existem itens a exibir. ***
                  </G.Text>
                }

                {(loading === false && filteredList.length > 0) &&
                  <>
                    {filteredList.map((item, key) => (
                      <G.TableArea key={key}>
                        <G.FieldArea size={'48px'} style={{ padding: '0px', justifyContent: 'center' }}>
                          {(item.usr_foto !== null && item.usr_foto !== '')
                            ? <G.Image 
                                src={`${process.env.REACT_APP_BACKEND}/download?file=${item.usr_foto}&token=${localStorage.getItem('SessionID')}`}
                                style={{ minWidth: '30px', height: '30px', borderRadius: '50%', border: item.usr_estado === 1 ? '2px solid #2E8B57' : '2px solid #E95839', marginRight: '10px', padding: '3px' }} 
                              />
                            : <AccountCircleIcon style={{ minWidth: '36px', height: '36px', color: 'var(--icon-color)',borderRadius: '50%', border:  item.usr_estado === 1 ? '2px solid #2E8B57' : '2px solid #E95839', marginRight: '10px' }}/>
                          }
                        </G.FieldArea>
                        <G.FieldArea size={'186px'}><G.Text>{item.usr_nome}</G.Text></G.FieldArea>
                        <G.FieldArea size={'120px'}><G.Text>{item.usr_login}</G.Text></G.FieldArea>
                        <G.FieldArea size={'126px'} style={{ padding: '0px 5px', margin: '0px 5px', borderRadius: '5px' }}>
                          <C.ContactTypeIconArea style={{ width: 'auto', justifyContent: 'start', borderRadius: 'inherit', backgroundColor: 'inherit' }}>
                            <C.ContactTypeIcon style={{ width: '30px', fontSize: '10px', fontWeight: 'bold', backgroundColor: item.usr_adm === 1 ? '#1E90FF' : '#555' }}>
                              ADM
                            </C.ContactTypeIcon>
                          </C.ContactTypeIconArea>
                          <C.ContactTypeIconArea style={{ width: 'auto', justifyContent: 'start', borderRadius: 'inherit', backgroundColor: 'inherit' }}>
                            <C.ContactTypeIcon style={{ width: '30px', fontSize: '10px', fontWeight: 'bold', backgroundColor: item.usr_cal === 1 ? '#FFB400' : '#555' }}>
                              CAL
                            </C.ContactTypeIcon>
                          </C.ContactTypeIconArea>
                          <C.ContactTypeIconArea style={{ width: 'auto', justifyContent: 'start', borderRadius: 'inherit', backgroundColor: 'inherit' }}>
                            <C.ContactTypeIcon style={{ width: '30px', fontSize: '10px', fontWeight: 'bold', backgroundColor: item.usr_aux === 1 ? '#2E8B57' : '#555' }}>
                              AUX
                            </C.ContactTypeIcon>
                          </C.ContactTypeIconArea>
                        </G.FieldArea>
                        <G.FieldArea size={'190px'}><G.Text>{item.usr_email}</G.Text></G.FieldArea>
                        <G.FieldArea size={'120px'}>
                          <G.Text>
                            {mask(item.usr_telefone, ['(99) 9999-9999', '(99) 9 9999-9999'])}
                          </G.Text>
                        </G.FieldArea>
                        
                        <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}>
                          <G.IconButton 
                            // onClick={() => (user.id === 1) ? EditClick(key) : undefined} // Para Versão Demo
                            onClick={() => EditClick(key)} 
                            // style={{ backgroundColor: (user.id === 1) ? '#1E90FF' : '#BBB', margin: '0px 1px' }} // Para Versão Demo
                            style={{ backgroundColor: '#1E90FF', margin: '0px 1px' }}
                          >
                            <EditIcon fontSize="small"/>
                          </G.IconButton>

                          <G.IconButton 
                            // onClick={() => (user.id === 1 && item.usr_id !== 1) ? ActClick(key) : undefined} // Para Versão Demo
                            onClick={() => ActClick(key)}
                            // style={{ backgroundColor: (user.id === 1 && item.usr_estado === 0) ? '#2E8B57' : (user.id === 1 && item.usr_estado !== 0) ? '#555' : '#BBB', margin: '0px 1px' }} // Para Versão Demo
                            style={{ backgroundColor: item.usr_estado === 0 ? '#2E8B57' : '#555', margin: '0px 1px' }}
                          >
                            {item.usr_estado === 0 ? <CheckIcon fontSize="small"/> : item.usr_estado === 1 ? <BlockIcon fontSize="small"/> : <PriorityHighIcon fontSize="small"/>}
                          </G.IconButton>

                          <G.IconButton 
                            // onClick={() => (user.id === 1 && item.usr_id !== 1) ? DelClick(key) : undefined} // Para Versão Demo 
                            onClick={() => DelClick(key)} 
                            // style={{ backgroundColor: (user.id === 1) ? '#E95839' : '#BBB', margin: '0px 1px'}} // Para Versão Demo
                            style={{ backgroundColor: '#E95839', margin: '0px 1px'}}
                          >
                            <DeleteIcon fontSize="small"/>
                          </G.IconButton>

                        </G.FieldArea>
                      </G.TableArea>
                    ))}
                  </>
                }
              </G.TableBody>

            </G.Box>
          </>
        }
      </G.Body>


      <G.Footer style={{ color: '#333', fontSize: '14px' }}>
        {/* <p> Copyright <CopyrightIcon style={{ fontSize: '14px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
      </G.Footer>


        {modal !== false &&
          <M.ModalContainer>
            <M.ModalContentSmall>

            <M.ModalHeader 
              style={{ 
                backgroundColor: modalType === 1 ? '#BDF5D6' : modalType === 2 ? '#C8E4FF' : modalType === 3 ? '#FCAC9B' : (filteredList[selectedKey].usr_estado === 0 ? '#BDF5D6' : '#ccc'), 
                justifyContent: 'space-between', 
                bordeRadius: '15px' 
              }}>
              <C.ModalTitle>
                {modalType === 1 ? 'Adicionar Usuário' : modalType === 2 ? 'Editar Usuário' : modalType === 3 ? 'Excluir Usuário' : (filteredList[selectedKey].usr_estado === 0 ? 'Ativar Usuário' : 'Desativar Usuário')}
              </C.ModalTitle>
              <G.InfoArea>
                <HighlightOffIcon fontSize="large" style={{ cursor: 'pointer', color: '#333' }} onClick={CloseModalClick} />
              </G.InfoArea>
            </M.ModalHeader>

              <M.ModalBody style={{flexDirection: 'column', alignItems: 'center' }}>

              {(modalType === 2 || modalType === 3) && 
                <G.FormInput style={{ width: '80px'}} type="hidden" defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].usr_id} {...register("usr_id")} />
              }

              {(modalType === 1 || modalType === 2) &&
                <G.BoxBody style={{ flexDirection: 'column'}}>

                  <G.FormInputArea style={{ width: '100%' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #999' }}>
                      Nome
                    </G.FormInputLabel>
                    <G.FormInput autoFocus defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].usr_nome} {...register("usr_nome", {required:  true})} />
                    {/* {errors.usr_nome && <span style={{ fontSize: '12px', color: 'red', }}>(*) Digite um usr_nome.</span>} */}
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '100%' }}>
                    <G.FormInputLabel>
                      Nome Completo
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].usr_nome_completo} {...register("usr_nome_completo", {required:  true})} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '100%' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #999' }}>
                      Usuário
                    </G.FormInputLabel>
                    <G.FormInput style={{ backgroundColor: '#E0E1E2' }} defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].usr_login} {...register("usr_login", {required: true})} />
                  </G.FormInputArea>
                  
                  <G.FormInputArea style={{ width: '100%' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #999' }}>
                      E-mail
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].usr_email} {...register("usr_email", {required:  true})} />
                    {/* {errors.usr_email && <span style={{ fontSize: '12px', color: 'red', }}>(*) Digite um e-mail válido.</span>} */}
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '64%' }}>
                    <G.FormInputLabel>
                      Telefone
                    </G.FormInputLabel>
                    <G.FormInput 
                      type='text'
                      name='phoneNumber'
                      placeholder=''
                      value={
                        phoneNumber === null 
                        ? '' 
                        : mask(phoneNumber, ['(99) 9999-9999', '(99) 9 9999-9999'])
                      }
                      onChange={onChangePhone}
                      // defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].usr_telefone} {...register("usr_telefone")} 
                    />
                  </G.FormInputArea>

                  {(Object.keys(errors).length !== 0 || stateErrors) &&  
                    <div role="alert" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '88%', padding: '7px', margin: '16px', borderRadius: '4px', fontSize: '12px', backgroundColor: '#FCAC9B', color: '#000' }}>
                      <WarningRoundedIcon  style={{ color: '#333', marginRight: '5px' }} />
                      {'TODOS OS CAMPOS ACIMA DEVEM ESTAR PREENCHIDOS!'}
                    </div>
                  }

                  <div style={{ display: 'flex', width: '408px', height: '35px', padding: '0px 0px 8px 0px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Função do Usuário
                  </div>
                  <div style={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between' }}>
                    <G.FormInputArea style={{ width: '128px'}}>
                      {/* <G.FormInputLabel style={{ height: '32px', borderLeft: '3px solid #1E90FF' }}>Administrador</G.FormInputLabel> */}
                      <G.FormInput style={{ width: '20px' }} type="checkbox" value={admField} checked={admField} onChange={e => setAdmField(!admField)} />
                      <G.FormInputLabel style={{ height: '32px', justifyContent: 'center', borderLeft: 'none', border: '1px solid #1E90FF', borderRadius: '4px', color: '#1E90FF' }}>Administrador</G.FormInputLabel>
                    </G.FormInputArea>
                    <G.FormInputArea style={{ width: '128px' }}>
                      {/* <G.FormInputLabel style={{ height: '32px', borderLeft: '3px solid #FFB400' }}>Calculista</G.FormInputLabel> */}
                      <G.FormInput style={{ width: '20px' }} type="checkbox" value={calField} checked={calField} onChange={e => setCalField(!calField)} />
                      <G.FormInputLabel style={{ height: '32px', justifyContent: 'center', borderLeft: 'none', border: '1px solid #FFB400', borderRadius: '4px', color: '#FFB400' }}>Calculista</G.FormInputLabel>
                    </G.FormInputArea>
                    <G.FormInputArea style={{ width: '128px' }}>
                      {/* <G.FormInputLabel style={{ height: '32px', borderLeft: '3px solid #2E8B57' }}>Auxiliar</G.FormInputLabel> */}
                      <G.FormInput style={{ width: '20px' }} type="checkbox" tintColors={ '#2E8B57' } value={auxField} checked={auxField} onChange={e => setAuxField(!auxField)} />
                      <G.FormInputLabel style={{ height: '32px', justifyContent: 'center', borderLeft: 'none', border: '1px solid #2E8B57', borderRadius: '4px', color: '#2E8B57' }}>Auxiliar</G.FormInputLabel>
                    </G.FormInputArea>
                  </div>
                </G.BoxBody>
              }
              </M.ModalBody>

              {/* EXCLUIR CADASTRO DE USUÁRIO */}
              {(modalType === 3) && 
              <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                <G.Text style={{ margin: '10px 0px' }}>
                  Deseja excluir o Usuário:
                </G.Text>
                <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                  <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                    {filteredList[selectedKey].usr_nome}
                  </G.FormInputLabel>
                </G.FormInputArea>
              </M.ModalBody>
            }

            {/* ATIVA OU DESATIVAR CADASTRO DE USUÁRIO */}
            {(modalType === 4) && 
              <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                <G.Text style={{ margin: '10px 0px' }}>
                  Deseja {filteredList[selectedKey].usr_estado === 0 ? 'ativar' : 'desativar'} o Usuário:
                </G.Text>
                <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                  <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                    {filteredList[selectedKey].usr_nome}
                  </G.FormInputLabel>
                </G.FormInputArea>
              </M.ModalBody>
            }

            <M.ModalFooter style={{ flexDirection: 'row', justifyContent: 'center' }}>
              {alertError !== '' &&
                <C.Alert><WarningIcon style={{marginRight: '10px', fontSize: '30px', color: '#333' }} />{alertError}</C.Alert>
              }
              
              {/* ADICIONAR OU ATUALIZAR O CADASTRO DE USUÁRIO */}
              {(modalType === 1 || modalType === 2) &&
                // <G.Button onClick={SendUsers} style={{backgroundColor: '#333', width: '120px'}}>Enviar</G.Button>
                // <G.Button style={{backgroundColor: '#333'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                <>
                  <G.Button style={{backgroundColor: '#E95839'}} onClick={() => CloseModalClick2()}>Fechar</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                </>
              }

              {/* EXCLUIR CADASTRO DE USUÁRIO */}
              {modalType === 3 && 
                <>
                  <G.Button style={{backgroundColor: '#E95839', width: '120px'}} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57', width: '120px'}} onClick={() => DelUser(filteredList[selectedKey].usr_id) + CloseModalClick()}>Sim</G.Button>
                </>
              }

              {/* ATIVA OU DESATIVAR CADASTRO DE USUÁRIO */}
              {modalType === 4 && 
                <>
                  <G.Button style={{ backgroundColor: '#E95839', width: '120px' }} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{ backgroundColor: '#2E8B57', width: '120px' }} onClick={() => ActUser(filteredList[selectedKey].usr_id, filteredList[selectedKey].usr_estado === 0 ? 1 : 0) + CloseModalClick2()}>Sim</G.Button>
                </>
              }
            </M.ModalFooter>

            </M.ModalContentSmall>
          </M.ModalContainer>
        }
    </G.Container>
  );
}
